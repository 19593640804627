<!--<div class="modal fade bd-example-modal-lg" id="myModal2">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      &lt;!&ndash; Modal Header &ndash;&gt;
      <div class="modal-header">
        <h4 class="modal-title descriptionHeader">Booking Details</h4>
        <button type="button" class="book-now-btn" style="background-color: #14274E; color:white;" (click)="hideModal()">Close</button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-9">
            <h6 class="field-name descriptionHeader">{{data?.customerName}}</h6>
            <table class="table table-borderless bookedSlotsFieldName">
              <tr>
                <td>BOOKING ID</td>
                <td class ="bookedSlotsFieldValue">{{data?.bookingId}}</td>
              </tr>
              <tr>
                <td>DATE & TIME</td>
                <td class ="bookedSlotsFieldValue">{{data?.bookingDate}}  &nbsp; {{data?.startTime}}   &nbsp; {{data?.endTime}} </td>
              </tr>
              <tr>
                <td>EMAIL ID</td>
                <td class ="bookedSlotsFieldValue">{{data?.customerEmail}}</td>
              </tr>
              <tr>
                <td>PHONE NO</td>
                <td class ="bookedSlotsFieldValue">{{data?.customerPhone}}</td>
              </tr>
              <tr>
                <td>AMOUNT</td>
                <td class ="bookedSlotsFieldValue">{{data?.price}}</td>
              </tr>
              <tr>
                <td>PAYMENT</td>
                <td class ="bookedSlotsFieldValue">{{data?.paymentType}}</td>
              </tr>
              <tr>
                <td>BOOKED ON</td>
                <td class ="bookedSlotsFieldValue">{{formatDate(data?.bookedOn)}}</td>
              </tr>
              <tr>
                <td>NOTES</td>
                <td class ="bookedSlotsFieldValue">{{data?.notes}}</td>
              </tr>
            </table>
            <div class="col-2 remove-icon">
              <img src="./../../assets/img/delete.svg" (click)="removeBooking(data)"/>
            </div>
            <div class="col-12 dialog-footer">
              <button mat-button mat-dialog-close>Close</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->




        <!--<h5 class="field-name descriptionHeader">Booking Details</h5>-->
      <h2 mat-dialog-title>Booking Details</h2>
        <mat-dialog-content style = "min-height: 600px;min-width: 400px;">
                 <!--<h6 class="field-name descriptionHeader" style="margin-top:10px;"></h6>-->
          <table class="table table-borderless bookedSlotsFieldName" style="justify: left;">
            <tr>
              <td>NAME</td>
              <td class ="bookedSlotsName">{{data?.customerName}}</td>
            </tr>
            <tr>
              <td>BOOKING ID</td>
              <td class ="bookedSlotsFieldValue">{{data?.bookingId}}</td>
            </tr>
            <tr>
              <td>VENUE</td>
              <td class ="bookedSlotsFieldValue">{{data?.venueName}}</td>
            </tr>
            <tr>
              <td>DATE</td>
              <td class ="bookedSlotsFieldValue">{{ dateService.getFormattedDate(data?.bookingDate) }}
              </td>
            </tr>
            <tr>
              <td>TIME</td>
              <td class ="bookedSlotsFieldValue">{{ dateService.convertTo12HourFormat(data?.startTime) }} to {{ dateService.convertTo12HourFormat(data?.endTime) }}
              </td>
            </tr>
            <tr>
              <td>EMAIL ID</td>
              <td class ="bookedSlotsFieldValue">{{data?.customerEmail}}</td>
            </tr>
            <tr>
              <td>PHONE NO</td>
              <td class ="bookedSlotsFieldValue">{{data?.customerPhone}}</td>
            </tr>
            <tr>
              <td>AMOUNT</td>
              <td class ="bookedSlotsFieldValue">{{data?.price}}</td>
            </tr>
            <tr>
              <td>PAYMENT</td>
              <td class ="bookedSlotsFieldValue">{{data?.paymentType}}</td>
            </tr>
            <tr>
              <td>BOOKED ON</td>
              <td class ="bookedSlotsFieldValue">{{formatDate(data?.bookedOn)}}</td>
            </tr>
            <tr>
              <td>NOTES</td>
              <td class ="bookedSlotsFieldValue" style="white-space: wrap;">{{data?.notes}}</td>
            </tr>
          </table>
          <button class="pop-up-btn-blue btn-action" mat-dialog-close (click)="deleteSlot(data)">Delete Booking</button>
          <button class="pop-up-btn-blue btn-action" mat-dialog-close>Close</button>
        </mat-dialog-content>

    <!--<div mat-dialog-actions>

    </div>-->
    <!--<div class="col-12">
      &lt;!&ndash;<button style="background-color:#14274E; color:#FFF; margin-bottom:10px; font-family: 'Nunito-R'; font-size: 0.8rem; padding: 5px 20px 5px 20px;" (click)="cancelBooking()">Cancel Booking</button>&ndash;&gt;
      <span style="margin-left: 90px;"></span>
      <button style="color:#14274E; margin-bottom:10px; font-family: 'Nunito-B'; font-size: 0.8rem; padding: 5px 20px 5px 20px;" mat-dialog-close>Close</button>

    </div>-->

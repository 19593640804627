import { Injectable } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }
  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  datePipe: DatePipe = new DatePipe('en-US');

  getFormattedDate(dateStr) {
    console.log("dateService: ", dateStr);
    const splitDateStr = dateStr.split('T');
    const dateValue = splitDateStr[0];
    const splitDateValue = dateValue.split('-');
    const formattedDate = this.datePipe.transform(dateStr, 'EEEE, dd MMM yy');
    console.log("formattedDate", formattedDate);
    //return splitDateValue[2] + ' ' + this.months[parseInt(splitDateValue[1], 10) - 1] + ' ' + splitDateValue[0]
    return formattedDate;
  }

  convertTo12HourFormat(time24: string): string {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(':').map(Number);

    // Check if hours are greater than 12
    if (hours > 12) {
      // Convert hours to 12-hour format
      const hours12 = hours - 12;
      return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} PM`;
    } else if (hours === 0) {
      // Midnight (00:00) is 12:00 AM in 12-hour format
      return `12:${minutes.toString().padStart(2, '0')} AM`;
    } else if (hours === 12) {
      // Noon (12:00) is 12:00 PM in 12-hour format
      return `12:${minutes.toString().padStart(2, '0')} PM`;
    } else {
      // Hours are already in 12-hour format
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} AM`;
    }
  }

  getFormattedTime(timeString) {
    let formattedTime = '';
    const splitTimeString = timeString.split(':');
    let timeValue = parseInt(splitTimeString[0], 10);
    const minsValue = parseInt(splitTimeString[1], 10);

    if (timeValue >= 12) {
      if (timeValue !== 12) {
        timeValue = timeValue % 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' PM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' PM';
      }
    } else {
      if (timeValue === 0) {
        timeValue = 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' AM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' AM';
      }
    }
    return formattedTime;
  }

  getDateFormatWithSeparator(date: Date, separator = '-') {
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + separator + month + separator + day;
  }

  getDayHeader(dateTime) {
    if (dateTime) {
      const dateObj = new Date(dateTime);
      return dateObj.getDate() + ' ' + this.months[dateObj.getMonth()];
    }
    return '';
  }

  getWeekDay(dateTime) {
    if (dateTime) {
      const dateObj = new Date(dateTime);
      return this.weekdays[dateObj.getDay()]
    }
    return '';
  }

}

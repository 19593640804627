<div class="main-container" *ngIf="!isUserDefined()">
  <div style="background-color:#fff;">
    <div class="container">
      <!-- Sign Up Section -->
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="row sign-up-link" style="margin-top: 20px;">
          <button
            class="btn"
            style="text-decoration: underline; color: #14274E; background-color: #FFF; font-size: 25px; font-family: 'Nunito-B'"
            (click)="onClickSignUpEndUser()"
          >
            SignUp
          </button>
          <p style="margin-top: 20px; color: #EC8B5D; font-size: 25px; font-family: 'Nunito-B';">for Booking</p>
        </div>
      </div>
      <!-- Main Content Row: Adjusted for Desktop and Mobile -->
      <div class="row align-items-center">
        <!-- Left Half: Text and Button -->
        <div class="col-12 col-md-5 d-flex flex-column align-items-center align-items-md-start text-md-start">
          <h1 class="boldStatements mobile-align" style="margin-top: 20px;">
            Online Booking Platform for your
            <span style="color: #EC8B5D;">Venue and Services</span>
          </h1>
          <p class="description mobile-align" style="font-size: 20px; margin-top: 20px;">
            One stop solution to manage your Bookings
          </p>
          <button class="btn createNewButtonStyle" type="button" (click)="onClickCreateNow()" style="width: 330px; margin-top: 20px;">
            Register your Business for Free
          </button>
        </div>

        <!-- Right Half: Image (Hidden on Mobile) -->
        <div class="col-md-7 d-none d-md-flex justify-content-center">
          <img
            class="small-image"
            src="../../assets/img/HomeImage.png"
            style="height: 350px; width: 603px; background-repeat: no-repeat; margin-top: 20px;"
          />
        </div>
      </div>

      <!-- Business Types Section -->
      <div class="row">
        <div class="col">
          <h3 class="descriptionHeader text-center mt-5">Business Types</h3>
        </div>
      </div>

      <!-- Responsive Business Types layout -->
      <div class="row justify-content-center mt-4">
        <div
          class="col-4 col-sm-3 col-md-2 text-center mb-4"
          (click)="navigateToBusinesses()"
          *ngFor="let business of businessTypes"
        >
          <img class="bdhover" [src]="business.icon" style="height: 70px; width: 70px;" />
          <p class="mt-2 font-weight-bold">{{ business.name }}</p>
        </div>
      </div>

      <!-- Business Admin Features Section -->
      <div class="row">
        <div class="col">
          <h3 class="descriptionHeader text-center mt-5">Business Admin Features</h3>
        </div>
      </div>

      <!-- Responsive Business Features layout -->
      <div class="row justify-content-center" style="margin-top: 20px;">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *ngFor="let feature of businessFeatures">
          <div class="d-flex justify-content-center align-items-center">
            <img [src]="feature.icon" style="height: 40px; width: 40px; margin-right: 10px;" />
            <h5 style="font-weight: bold; margin: 0;">{{ feature.title }}</h5>
          </div>
          <!-- Wrapped text with proper indentation using flex and alignment -->
          <ul style="list-style: none; padding-left: 0; margin-top: 10px; max-width: 340px;">
            <li
              *ngFor="let detail of feature.details"
              style="display: flex; padding-top: 3px; align-items: flex-start; font-family: 'NunitoSans-SB'; "
            >
              <span style="min-width: 20px;"></span>
              <span style="flex: 1; ">{{ detail }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<div *ngIf="isUserDefined()">
  <div class="container">
    <div class="row">
      <!-- background-color: white; -->
      <div class="col-md-11" style="margin-top: 20px; margin-bottom: 20px;">
        <!-- <app-sign-up-success></app-sign-up-success> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
    <div class="modal-body">
        <div class="container" style="height:100vh; min-height: 800px; width: 430px;border-radius: 15px 15px 15px 15px;box-shadow: -5px 0px 8px #00000029;background-color: rgb(255,255,255);">
            <button class="close-button" type="button" (click)="onCancelLogin()" style="top: 10px; right: 10px; margin-top: 15px;">
              X
            </button>
            <div class="row justify-content-center">
                <div class="col-auto" style="padding-top: 20px; ">
                  <img class = "center" src="../assets/img/Logo.svg" alt="Lights" style="width: 45px;height: 45px;">
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div *ngIf="showSignUpForm" style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%;">
                        <form [formGroup]="signUpFormGroup" style="width: 100%; max-width: 400px; text-align: center;">
                          <div class="row">
                            <div class="col" style="margin-top: 20px;">
                              <h1 style="font-size: 30px;color: #14274e; font-family: 'Nunito-B';">
                                Join Our Family
                              </h1>
                              <p style="font-size: 13px;color: #7c7575;height : 18px">
                                And be part of this amazing community
                              </p>
                            </div>
                          </div>
                            <div class="form-group">
                                <div style="margin-top: 10px;">
                                  <label for="first-name" style="float: left;font-size: 11px; color: #7c7575; font-weight: bold;">
                                    FIRST NAME
                                  </label>
                                  <input type="text" id="first-name" class="form-control" formControlName="firstNameFormControl" placeholder="First Name"
                                         style="width: 90%; height: 40px;" />
                                  <div *ngIf="signUpFormControls.firstNameFormControl.hasError('required') && signUpFormControls.firstNameFormControl.touched" style="color: red; font-size: 14px;">
                                    First Name is required.
                                  </div>
                                </div>
                              <div style="margin-top: 5px;">
                                <label for="last-name" style="float: left; font-size: 11px; color: #7c7575; font-weight: bold;">
                                  LAST NAME
                                </label>
                                <input type="text" id="last-name" class="form-control" formControlName="lastNameFormControl" placeholder="Last Name"
                                       style="width: 90%; height: 40px;" />
                                <div *ngIf="signUpFormControls.lastNameFormControl.hasError('required') && signUpFormControls.lastNameFormControl.touched" style="color: red; font-size: 14px;">
                                  Last Name is required.
                                </div>
                              </div>

                              <div style="margin-top: 5px;">
                                <label for="email-address" style="float: left;font-size: 11px; color: #7c7575; font-weight: bold;">
                                  EMAIL ADDRESS
                                </label>
                                <input type="text" id="email-address" class="form-control" formControlName="emailFormControl" placeholder="Email"
                                       style="width: 90%; height: 40px;" />
                                <div *ngIf="signUpFormControls.emailFormControl.hasError('required') && signUpFormControls.emailFormControl.touched" style="color: red; font-size: 14px;">
                                  Email is required.
                                </div>
                                <div *ngIf="signUpFormControls.emailFormControl.hasError('email') && signUpFormControls.emailFormControl.touched" style="color: red; font-size: 14px;">
                                  Invalid email address.
                                </div>
                              </div>
                              <div style="margin-top: 5px;">
                                <label for="contact-number" style="float:left; font-size: 11px; color: #7c7575; font-weight: bold;">
                                  CONTACT NUMBER
                                </label>
                                <!--<div>
                                  <label style="line-height: 41px; z-index: 99; position: absolute; margin-left: 10px;" for="contact-number">+91 - </label>
                                </div>-->
                                <input type="text" id="contact-number" class="form-control" formControlName="phoneNoFormControl" placeholder="Phone Number" maxlength="10"
                                       style="width: 90%; height: 40px;" />
                                <div *ngIf="signUpFormControls.phoneNoFormControl.hasError('required') && signUpFormControls.phoneNoFormControl.touched" style="color: red; font-size: 14px;">
                                  Phone Number is required.
                                </div>
                                <div *ngIf="signUpFormControls.phoneNoFormControl.hasError('pattern') && signUpFormControls.phoneNoFormControl.touched" style="color: red; font-size: 14px;">
                                  Invalid phone number. Must be 10 digits.
                                </div>
                              </div>
                              <div class="form-check" style="padding-top: 15px;">
                                <input type="checkbox" id="checkbox-login" class="form-check-input" formControlName="checkboxFormControl" style="width: 16px; height: 16px; margin-top: 7px;" />
                                <label class="form-check-label" for="checkbox-login" style="font-size: 14px; color: #7c7575; font-family: 'Nunito-B';">
                                  I have read and agreed to <br>
                                  <a href="#" routerLink="/terms" style="height: 40px; padding: 10px;">Terms &amp; Conditions and Privacy Policy</a>.
                                </label>
                                <div *ngIf="signUpFormControls.checkboxFormControl.hasError('required') && signUpFormControls.checkboxFormControl.touched" style="color: red; font-size: 14px;">
                                  You must agree to the terms and conditions.
                                </div>
                              </div>

                                <div *ngIf="userExistsError" style="margin-top: 10px; color: red;font-size: 14px;">
                                  User Email Address or Phone Number already exists.
                                </div>
                                <div style="margin-top: 20px;">
                                    <button class="btn btn-primary" type="button" (click)="onGetStartedClicked()"  style="background-color: #14274e;height: 40px;width : 200px; padding-top: 6px;border-radius: 4px;padding-bottom: 9px;">
                                        Get Started
                                    </button>
                                </div>
                            </div>
                            <div class="center" style="margin-top: 30px;">
                              <p style="font-size: 14px; font-family: 'Nunito-B';">
                                Already Have an Account?  <a (click)="onShowSignInFormClicked()" style="cursor: pointer; color: #14274E; font-size: 20px; font-family: 'Nunito-B';"> Sign In</a>
                              </p>
                            </div>
                         </form>
                      <!--
                            <div class = "center" style="margin-top: 20px;">
                              <p style="font-size: 14px;">
                                  Already Have an Account?
                              </p>
                              <p>
                                <a (click)="onShowSignInFormClicked()" style="cursor: pointer; color: #14274E; font-size: 16px; font-family: 'Nunito-B';"> Sign In</a>
                              </p>
                            </div>-->
                    </div>


                    <div *ngIf="showSignInForm" style="display: flex; justify-content: center; align-items: center;">

                        <form [formGroup]="signInFormGroup" style="width: 100%; max-width: 400px; text-align: center;">
                            <div class="form-group" style="margin-right: 30px;margin-left: 5px;width: 370px;">
                              <div class="row">
                                <div style="margin-top: 20px;">
                                  <h1 style="font-size: 30px; color: #14274e; font-family: 'Nunito-B';">
                                    Sign In
                                  </h1>
                                </div>
                              </div>
                                <div style="margin-top: 20px;">
                                    <label style="float: left; font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="email-address">
                                        EMAIL ADDRESS
                                    </label>
                                    <!-- this is to avoid auto focus on first input element -->
                                    <input type="text" style="display:none" />
                                    <input type="text" id="email-address" class="form-control" formControlName="emailFormControl" placeholder="Email"
                                        style="width: 90%; height: 40px; padding-right: 5px;" />
                                    <div *ngIf="signInFormControls.emailFormControl.hasError('required') && signInFormControls.emailFormControl.touched" style="color: red;font-size: 14px;">
                                        Email is required.
                                    </div>
                                </div>
                                <div style="margin-top: 15px;">
                                    <label style="float: left; font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="password">
                                        PASSWORD
                                    </label>
                                    <a (click)="onShowForgotPasswordFormClicked()" style="float: right; cursor: pointer; color: #14274E; font-size: 13px; font-family: 'Nunito-B';padding-right: 15px;"> Forgot Password?</a>

                                    <input type="password" id="password" class="form-control" formControlName="passwordFormControl" placeholder="Password"
                                        style="width: 90%;height: 40px;padding-right: 5px; " (input)="onPasswordInput()"/>
                                    <div *ngIf="signInFormControls.passwordFormControl.hasError('required') && signInFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                                        Password is required.
                                    </div>
                                </div>

                                <div *ngIf="showSignInError" style="color: red;font-size: 14px; margin-top: 20px;">
                                  {{signInError}}
                                </div>

                                <div style="margin-top: 20px;">
                                    <button class="btn btn-primary" type="button" (click)="onClickedSignIn()" style="background-color: #14274e; height: 40px; width: 50%; border-radius: 4px;">
                                        Sign In
                                    </button>
                                </div>
                                <div class="center" style="padding-top: 15px;">
                                  <p style="color: #14274E; font-size: 14px; font-family: 'Nunito-B';">
                                    OR
                                  </p>
                                </div>
                                <div class="center">
                                  <asl-google-signin-button type="icon" type="standard"></asl-google-signin-button>
                                </div>
                                <div class="center" style="margin-top: 30px;">
                                  <p style="font-size: 14px; font-family: 'Nunito-B';">
                                    Do not Have an Account?  <a (click)="onShowSignUpFormClicked()" style="cursor: pointer; color: #14274E; font-size: 20px; font-family: 'Nunito-B';"> Sign Up</a>
                                  </p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="showForgotPasswordForm">
                      <form [formGroup]="forgotPasswordFormGroup">
                        <div class="form-group" style="margin-right: 30px;margin-left: 5px;width: 370px;">
                          <div class="row">
                            <div class="col center" style="margin-top: 20px;">
                              <h1 class="text-center" style="font-size: 30px; padding: 0px;color: #14274e; font-family: 'Nunito-B';">
                                Forgot Password
                              </h1>
                            </div>
                          </div>
                          <div *ngIf="isEmailFilled()" style="margin-top: 20px;">
                            <label style="font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="email-address">
                                EMAIL ADDRESS
                            </label>
                            <input type="text" style="display:none" />
                            <input disabled type="text" id="email-address" class="form-control" formControlName="emailFormControl"
                                  style="width: 325px;height: 40px;" />
                          </div>
                          <div *ngIf="!isEmailFilled()" style="margin-top: 20px;">
                            <label style="font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="email-address">
                                EMAIL ADDRESS
                            </label>
                            <input type="text" style="display:none" />
                            <input type="text" id="email-address" class="form-control" formControlName="emailFormControl" placeholder="Email"
                                  style="width: 325px;height: 40px;" />
                            <div *ngIf="forgotPasswordFormControls.emailFormControl.hasError('required') && forgotPasswordFormControls.emailFormControl.touched" style="color: red;font-size: 14px;">
                                    Email is required.
                            </div>
                            <div *ngIf="forgotPasswordFormControls.emailFormControl.hasError('invalid') && forgotPasswordFormControls.emailFormControl.touched" style="color: red;font-size: 14px;">
                              Email is invalid.
                            </div>
                          </div>
                          <div  style="margin-top: 20px;margin-left: 261px;">
                            <label (click)="onClickedSendOTP()" style="cursor: pointer; font-size: 14px;height: 15px; font-weight: bold;" for="otp">
                              Send OTP
                            </label>
                          </div>
                          <div *ngIf="sendOTPClicked && !sendOTPerror">
                            {{messageToDisplayAfterOtpSent}}
                          </div>
                          <div *ngIf="sendOTPClicked && sendOTPerror" style="color: red;font-size: 14px; margin-top: 20px;">
                            {{messageToDisplayAfterOtpSent}}
                          </div>

                          <div>
                            <form [formGroup]="verifyOTPFormGroup">
                              <div class="row" style="margin-left: 25px; margin-top: 40px;">
                                <div class="col">
                                    <div style="width: 50px;height: 50px;float: left;margin-right: 20px;">
                                        <input id="codeBox1" type="text" formControlName="regotp0" maxlength="1" (keyup)="onKeyUpEvent(1, $event)" (focus)="onFocusEvent(1)" style="width: inherit;height: inherit;background-color: #cbcece;border-radius: 12px;border: none;text-align: center;" />
                                    </div>
                                    <div style="width: 50px;height: 50px;float: left;margin-right: 20px;">
                                        <input id="codeBox2" type="text" formControlName="regotp1" maxlength="1" (keyup)="onKeyUpEvent(2, $event)" (focus)="onFocusEvent(2)" style="width: inherit;height: inherit;background-color: #cbcece;border-radius: 12px;border: none;text-align: center;" />
                                    </div>
                                    <div style="width: 50px;height: 50px;float: left;margin-right: 20px;">
                                        <input id="codeBox3" type="text" formControlName="regotp2" maxlength="1" (keyup)="onKeyUpEvent(3, $event)" (focus)="onFocusEvent(3)" style="width: inherit;height: inherit;background-color: #cbcece;border-radius: 12px;border: none;text-align: center;" />
                                    </div>
                                    <div style="width: 50px;height: 50px;float: left;margin-right: 20px;">
                                        <input id="codeBox4" type="text" formControlName="regotp3" maxlength="1" (keyup)="onKeyUpEvent(4, $event)" (focus)="onFocusEvent(4)" style="width: inherit;height: inherit;background-color: #cbcece;border-radius: 12px;border: none;text-align: center;" />
                                    </div>
                                </div>
                              </div>
                              <label style="font-size: 14px;height: 15px; font-weight: bold;margin-top: 15px; margin-left:30px;">
                                OTP Validity : 5 Minutes
                              </label>
                              <div class="row" style="margin-left: 30px;margin-top: 20px;">
                                <div class="col">
                                    <div *ngIf="showOnClickVerifyError" style="color: red;">{{onClickVerifyError}}</div>
                                </div>
                            </div>
                              <div class="row" style="margin-left: 30px;margin-top: 34px;">
                                <div class="col">
                                    <button class="btn btn-primary" type="button" (click)="onClickVerify()" style="background-color: #14274e;width: 100px;">
                                        Verify
                                    </button>
                                    <button class="btn btn-primary" type="button" (click)="onClickForgotPasswordCancel()" style="background-color: #14274e;width: 100px;margin-left: 60px;">
                                      Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>

                        </div>
                      </form>
                    </div>

                    <div *ngIf="showResetPasswordForm">
                      <form [formGroup]="resetPasswordFormGroup">
                        <div class="form-group" style="margin-right: 30px;margin-left: 5px;width: 370px;">
                          <div class="row">
                            <div class="col center" style="margin-top: 20px;">
                              <h1 class="text-center" style="font-size: 30px; padding: 0px;color: #14274e; font-family: 'Nunito-B';">
                                Reset Password
                              </h1>
                            </div>
                          </div>
                          <div style="margin-top: 20px;">
                            <label style="font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="password">
                                PASSWORD
                            </label>
                            <input type="text" style="display:none" />
                            <input type="password" formControlName="passwordFormControl" placeholder="Password"
                                  style="width: 325px;height: 40px;" />
                            <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('required') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                              Password is required
                            </div>
                            <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                              Password must have at least {{minPasswordLength}} characters
                            </div>
                            <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                              Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character e.g !@#$%^&*
                            </div>
                          </div>
                          <div style="margin-top: 20px;">
                            <label style="font-size: 11px;height: 15px;color: #7c7575;font-weight: bold;" for="reenter-password">
                                RE-ENTER PASSWORD
                            </label>
                            <input type="text" style="display:none" />
                            <input type="password" formControlName="reenterPasswordFormControl" placeholder="Password"
                                  style="width: 325px;height: 40px;" />
                            <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('required') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                              Password is required
                            </div>
                            <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                              Password must have at least {{minPasswordLength}} characters
                            </div>
                            <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                              Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character e.g !@#$%^&*
                            </div>
                            <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('confirmedValidator') && !resetPasswordFormControls.reenterPasswordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                              Passwords did not match
                            </div>
                          </div>
                          <div class="row" style="margin-left: 45px;margin-top: 20px;">
                            <div class="col">
                                <div *ngIf="showOnClickResetPasswordError" style="color: red;">{{onClickResetPasswordError}}</div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col" style="margin-left: 55px;">
                                <button class="btn btn-primary" [disabled]="resetPasswordFormGroup.invalid" type="button" (click)="onResetPasswordClicked()" style="background-color: #14274e;width: 200px;">
                                    Reset Password
                                </button>
                            </div>
                          </div>
                          <label style="font-size: 12px;height: 15px;padding-top: 10px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" >
                              Password must have at least {{minPasswordLength}} characters
                          </label>
                          <label style="font-size: 12px;height: 15px;color: #7c7575;font-weight: bold;" >
                              Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character e.g !@#$%^&*
                          </label>
                         </div>
                      </form>
                    </div>
            </div>
        </div>
        </div>
    </div>
  </ng-template>

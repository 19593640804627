import { Component, Inject, OnInit } from '@angular/core';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../service/auth.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginModalService } from '../login-modal.service';
import { BottomContainerComponent } from "../bottom-container/bottom-container.component";
import { Summary } from "../models/summary";
import { AppConstants } from '../AppConstants';
import { UIRoutes } from '../UIRoutes';
import { CheckoutService } from 'paytm-blink-checkout-angular';
import { Subscription } from 'rxjs';
import { ICheckoutInstance } from 'paytm-blink-checkout-angular/lib/models';
import { HttpErrorResponse } from '@angular/common/http';

// This lets me use jquery
declare var $: any;

export class selectedSlotObj {
           uniqueId: string;
           ss_service: string;
           ss_productId: string;
           ss_slotDate: string;
           ss_startTime: string;
           ss_endTime: string;
           amount: string;
           ss_slotId_td: string;
           ss_webAppId: number;
           ss_slotType: string;
           ss_venueId: string;
           category: string;
           ss_tenantId: string;
           userId: string;
           advanceProductId: number;
           paymentType: string;
           notes: string;
           startDate:string; //for Multisession and monthly type service only

    constructor(uniqueId: string, venueId: string, startTime: string, endTime: string, price: string, slotId_td: string, slotType: string)
    {
      this.uniqueId = uniqueId;
      this.ss_venueId = venueId;
      this.ss_startTime = startTime;
      this.ss_endTime = endTime;
      this.amount = price;
      this.ss_slotId_td = slotId_td;
      this.ss_slotType = slotType;
      this.category = "placeholder";
      this.advanceProductId= 1;
      this.notes = "External";
    }
}

@Component({
  selector: 'app-product-landing',
  templateUrl: './product-landing.component.html',
  styleUrls: ['./product-landing.component.css']
})
export class ProductLandingComponent implements OnInit{
  // model: NgbDateStruct;

  /** bsModalRef: BsModalRef; */
  // bsModalRef: BsModalRef;


  selectedSlotsMap = new Map();
  days = [];
  dt = new Date();
  month = this.dt.getMonth() + 1;
  year = this.dt.getFullYear();
  onSinglehide: boolean = false;

  slots: any;
  endSoltTime: any;
  slotPrice = [];
  priceArr: number;
  totalPriceArr = [];
  //categoryArr = ['Sports', 'Law Firm', 'Spa and Salon', 'Meeting Rooms', 'Counselor', 'Clinics', 'Path Lab', 'Community Halls', 'Office Spaces', 'Repairing Services', 'Others'];
  //selectedCat: any;
  selectedProduct: string;
  selectedServiceType: string;
  sessionType:string;
  noOfSessions:number;
  monthlyMultiBookingOption:string;
  monthlyMultiBookingSelectedDates: number[];


  allSlotsLength: boolean;
  totalSum: number = 0;
  alertMessages: any;
  //isSelected: boolean = false;

  singleBooking: any;
  finalStartTime: any;
  finalEndTime: any;
  latest_date1: any;
  bntStyle: string;
  slotDate: any;
  tenant: any;
  products: any;
  selectedProductIndex: any;
  venues: any;
  allSlots: any;
  public id: any;
  public productId: any;
  public venueId: any;
  isTotalSumZero: boolean = true;
  slotIdArr: any;
  slotId: number;
  slotIdAfterSelect: number;
  userb: any;
  slotsAllLength: number;
  bookedslotsLength: number = 0;
  numberOfSlotsSelected: number = 0;
  toDate: any;
  maxDate:any;
  minDate:any;
  selectedStartDate: string;
  bookingSlots:any;
  datePipe: DatePipe = new DatePipe('en-US');

  categoryAndProduct: any;
  productByCat: any;
  isCatProd: boolean = true;
  //isSelectedCat: any; //"Badminton";
  thisProdId: any;
  dateChange: any;
  isSingle:boolean = true;
  Summary: Summary;
  slotsTypeNew: any;
  mainService: any;
  private subs: Subscription;
  openInPopup = true;
  isCheckoutVisible = true;
  config = {
    data: {
      confirmationId: "test1",
      amount: "1.00",
      token: "89c2446b174e4b4096ad10df8d3209dc1676880761206",
      tokenType: "TXN_TOKEN"
    },
    merchant: {
      mid: "NgXarD42151468596686",
      name: "bookingdexter.com",
      redirect: false
    },
    flow: "DEFAULT"
  }
  checkoutJsInstance: ICheckoutInstance;

  // constructor() { }
  constructor(private authService: AuthService,
    private aRoute: ActivatedRoute, private router: Router,
    private loginModalService: LoginModalService, private readonly checkoutService: CheckoutService,
    /* private bottomContainerComponent: BottomContainerComponent */ ) {

        // Initialize latest_date1 with the current date
        this.latest_date1 = new Date().toISOString().split('T')[0];

        // Calculate the maximum allowable date (current date + 1 month)
        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 1);
        this.maxDate = maxDate.toISOString().split('T')[0];

    }

  ngOnInit() {
    this.userb = JSON.parse(sessionStorage.getItem('current-user-context'));
    var date = new Date();
    this.latest_date1 = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.dateChange = this.latest_date1;
    this.setMinDate();
    this.id = this.aRoute.snapshot.paramMap.get('id');
    //console.log("Init : this.id", this.id);
    this.getTenantById(this.id);
    //console.log("Init : Tenant", this.tenant);
    this.getVenuesByIdSau(this.id);
    this.getProductByIdSau(this.id);
    this.selectedProductIndex = 0;

    //this.selectedServiceType = this.products[this.selectedProductIndex].productType
    console.log("On Init", this.selectedServiceType);
    console.log("Init products : ", this.products);
    console.log("Date : ", this.latest_date1);
    //console.log("Product: ", this.products);
    // this.getAllSlotsByTenant(this.id, this.latest_date1, 1, "Single Session");
    //this.onDateChange(this.latest_date1);
    //this.selectedCat = this.categoryArr[0];
    if(this.totalSum == 0){
      this.isTotalSumZero = false;
    }

    this.subs  =  this.checkoutService.checkoutJsInstance$.subscribe(instance=>{
      // store this instance in some variable
      this.checkoutJsInstance = instance;
  });

  }
/*   onDateChange1(selectedDate: string): void {
      const date = new Date(selectedDate);

      // Get the day of the month
      const dayOfMonth = date.getDate();

      // Check if the day is allowed
      if (this.monthlyMultiBookingSelectedDates.includes(dayOfMonth)) {
        this.latest_date1 = selectedDate; // Accept the date
      } else {
        alert('Selected date is not allowed.'); // Inform the user
        this.latest_date1 = ''; // Reset the date
    }
  } */


  futureDates: string[] = []; // Array to store generated future dates

  generateFutureDates(): void {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth(); // 0-indexed month
      const monthsToGenerate = 2; // Generate dates for the next 2 months
        this.futureDates.length = 0;
      // Loop through the current and next month
      for (let i = 0; i < monthsToGenerate; i++) {
        const month = currentMonth + i; // Calculate the month
        const year = currentYear + Math.floor(month / 12); // Handle year rollover
        const actualMonth = month % 12; // Adjust month to stay within 0-11 range

        // Loop through each day in X
        this.monthlyMultiBookingSelectedDates.forEach((day) => {
                console.log("Day", day);
                if (day > 0 && day <= this.daysInMonth(actualMonth, year)) {
                  const date1 = new Date(year, actualMonth, day);
                  console.log("Day", date1);
                  if (date1 > today) {
                    this.futureDates.push(date1.toLocaleDateString('en-CA')); // Store as 'YYYY-MM-DD'
                  }
                }
              });

      }

      if (this.futureDates.length > 0) {
            this.selectedStartDate = this.futureDates[0]; // Default to the first date
      }
      console.log('Generated Future Dates:', this.futureDates); // Log the dates
  }
  daysInMonth(month: number, year: number): number {
      return new Date(year, month + 1, 0).getDate(); // Get the last day of the month
  }

  getProductByIdSau(id) {
    console.log("Saving Id", id);
    this.authService.getAllProductsByTenantId(id).subscribe(
      (response:any) => {
        // this.products = response;
        console.log(response);
        this.products = response.filter(product => product.status === AppConstants.STATUS_ACTIVE);
        console.log("All Products", this.products);
        this.productId = this.products[0].id;
        this.selectedProduct = this.products[0].productName;
        console.log("Selected Product = ", this.products[0].productName);
        this.selectedServiceType = this.products[0].productType;
        if(this.selectedServiceType  == "Multi Session")
        {
          this.sessionType = this.products[0].sessionType;
          this.monthlyMultiBookingOption = this.products[0].monthlyMultiBookingOption

          if( this.monthlyMultiBookingOption === "Selected Dates")
          {
            this.monthlyMultiBookingSelectedDates = this.parseSelectedDates(this.products[0].monthlyMultiBookingSelectedDates);
            this.generateFutureDates();
          }
          else
          {
            this.monthlyMultiBookingSelectedDates.length = 0; //clear the array for other product types
          }
          console.log("MM Settings1 : ", this.monthlyMultiBookingOption );
          console.log("MM Settings2 : ", this.monthlyMultiBookingSelectedDates );
        }
        console.log("service Type : ", this.selectedServiceType );
        this.getAllSlotsByTenant(this.id, this.latest_date1, this.productId, this.selectedServiceType);
      },
      (errorResponse) => {
        console.log("Error while retrieving products by tenant id" + errorResponse);
      }
    );
  }
  // Function to parse and convert the string to an array of numbers
  parseSelectedDates(data: string): number[] {
    return data
      .split(',')              // Split the string by comma
      .map(date => date.trim()) // Trim any extra spaces
      .map(Number)              // Convert each element to a number
      .filter(num => !isNaN(num)); // Filter out any invalid numbers
  }
  notifyMerchantHandler = (eventType, data): void => {
    console.log('MERCHANT NOTIFY LOG', eventType, data);
  }

  transactionStatusHandler = (data): void => {
    console.log('transactionStatusHandler', data);
    this.toggleCheckout();
    // this.checkoutJsInstance.closePopup();
    // this.checkoutJsInstance.closeModal();
    // this.checkoutJsInstance.close();
    // this.checkoutJsInstance.close;

      var arrayJSon: string = '';
      for (const value of this.selectedSlotsMap.values())
      {
        //array_name.push(value);
         if (arrayJSon != '') {
            value.confirmationId = data.confirmationId
            value.paymentType = "PhonePe"
            arrayJSon =  arrayJSon + ","  + JSON.stringify(value);
         }
         else {
          value.confirmationId = data.confirmationId
          value.paymentType = "PhonePe"
          arrayJSon = JSON.stringify(value);
         }
      }
      arrayJSon = "[" +  arrayJSon + "]";
      console.log(arrayJSon);
      this.authService.confirmBooking(arrayJSon).subscribe(
          (response: any) => {

                this.hideModal();
                this.selectedSlotsMap.clear();
                //this.isBookingSuccessful = true;

                //alert("Booking is successful: " + response.confirmationId);
                //this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_PRODUCT_ALL);
                console.log("Booking is successful, response: ", response);
                const confirmationId = response.confirmationId;
                sessionStorage.setItem(AppConstants.CONFIRMATION_ID, confirmationId);
                this.router.navigate([UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS])
                  .then(() => {
                    window.location.reload();
                });
          },
          (error) => {
             //this.isBookingSuccessful = false;
             console.log("Booking failed, response: ", error);
             const confirmationId = error.confirmationId;
             sessionStorage.setItem(AppConstants.CONFIRMATION_ID, confirmationId);
             this.router.navigate([UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS])
                  .then(() => {
                    window.location.reload();
                });
          }
        );
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  appendHandler(config): any {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
      transactionStatus: this.transactionStatusHandler
    }

    return newConfig;
  }

  toggleCheckout(): void {
    this.isCheckoutVisible = false;
  }

  getCheckout() {
    console.log("isCheckoutVisible", this.isCheckoutVisible);
    this.isCheckoutVisible;
  }

  initializeCheckout(confirmationId, txnToken, amount): void {
    this.config.data.confirmationId = confirmationId;
    this.config.data.token = txnToken;
    this.config.data.amount = amount;
    const config = this.appendHandler(this.config);
    this.isCheckoutVisible = true;
    this.checkoutService.init(config, { env: 'STAGE', openInPopup: this.openInPopup});
  }

  // openLoginModal() {
  //   this.bsModalRef = this.modalService.show(ConfirmModelComponent);
  // }
  deleteSelectedSlot(event, slot){

      console.log("removing slot", slot.key);
      console.log("Map before: ", this.selectedSlotsMap);

      if(this.selectedSlotsMap.has(slot.key))
      {
         this.selectedSlotsMap.delete(slot.key);
         this.totalSum = this.totalSum - slot.value.amount;
         this.numberOfSlotsSelected = this.numberOfSlotsSelected - 1;

      }
      else
      {
          console.log("Key not found");
      }
      console.log("Map After: ", this.selectedSlotsMap);

  }


  getTenantById(id) {
    this.authService.getSauByTenantId(id).subscribe(
      (response) => {

        this.tenant = response;
        console.log("In getTenantById ", this.tenant);
      },
      (errorResponse) => {
        console.error("Failed to load tenant by id" + errorResponse);
      }
    );
  }


  getVenuesByIdSau(id) {
    var tempData = [];
    console.log("getVenuesByIdSau 1", id);
    this.authService.getAllVenuesByTenantId(id).subscribe(
      (response: any[]) => {
        console.log("getVenuesByIdSau 2", response);
        for(var i=0; i < Object.keys(response).length; i++){
          const venueObj = {
            venueType: response[i].venueType,
          }

          const found = tempData.filter(x => x.venueType == response[i].venueType)[0]
          if(!found){
            tempData.push(venueObj)
          }
        }

        this.venues = tempData;


      },
      (errorResponse) => {
        console.error("Error while retrieving venues by tenantId" + errorResponse);
      }
    );
  }


  getAllSlotsByTenant(id, latest_date1, productId, serviceType) {
      // Get slots by time sorted
      console.log("getAllSlotesByTenant ", serviceType );
      console.log("getAllSlotesByTenant  this.thisProdId ", this.thisProdId );
      this.getTimeWiseSlots(id, latest_date1, productId, '', serviceType);
  }

  getDaysInMonth(month, year) {
    month = this.dt.getMonth() + 2;

    return this.days;
  }


  days12 = this.getDaysInMonth(this.month, this.year);

  singleOrRecuringBook(book): void {
    if(book == 'rec'){
      this.onSinglehide = true;
    } else {
      this.onSinglehide = false;
    }
  }


  isLoggedIn() {
    if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
      console.log("External user", sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT));
      return true;
    } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
      console.log("Current user", sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT));
      return true;
    }
    else
    {
      console.log("Current user 2");
    }
  }

  getSlotBGColor(uniqueId)
  {
      if(this.selectedSlotsMap.has(uniqueId))
      {
        return '#20D45C';
      }
      else
      {
        return "white";
      }

  }


getSlotsDetails_TD(event, uniqueId, venueId, time, endTime, price, slotId_td, slotsType, mainService)
{

    // TD_Review Start
    if(!this.isLoggedIn())
    {
      console.log("Logged Out");
      this.loginModalService.setLoginModalData(true);
      this.router.navigate(['/login'])
    }
    else
    {
      this.userb = JSON.parse(sessionStorage.getItem('current-user-context'));
      console.log("Logged in");
      console.log(this.userb);

      // TD_Review End

      let key = uniqueId;

      if(this.selectedSlotsMap.has(key)){
        this.selectedSlotsMap.delete(key);
        this.totalSum = this.totalSum - price;
        this.numberOfSlotsSelected = this.numberOfSlotsSelected - 1;
        console.log(this.totalSum);
        console.log('removed');

      } else {

        let selectedSlot = new selectedSlotObj(uniqueId, venueId, time, endTime, price, slotId_td, slotsType);
        //console.log("selected Service : ", this.selectedProduct);
        selectedSlot.ss_service = this.selectedProduct;
        selectedSlot.ss_productId = this.productId; // service and product are same.
        selectedSlot.ss_slotDate = this.datePipe.transform(this.dateChange, 'd MMM y');
        selectedSlot.userId = this.userb.userId;
        if(this.sessionType === "Monthly")
        {
          selectedSlot.startDate = this.selectedStartDate;
          console.log("selected startDate : ", this.selectedStartDate);
        }
        //selectedSlot.ss_venueId = this.venueId;
        console.log(slotsType+ this.dateChange+time);

        //Adding into the map of selected slots.
        this.selectedSlotsMap.set(key, selectedSlot);
        console.log("added slot", this.selectedSlotsMap);
        console.log("Added");
        this.totalSum = this.totalSum + price;
        this.numberOfSlotsSelected = this.numberOfSlotsSelected +1;
        console.log(this.totalSum);

      }
      if(this.totalSum == 0){
        this.isTotalSumZero = false;
      }
      else
      {
        this.isTotalSumZero = true;
      }

    }
}

checkIfDateIsLaterThanMonth(dateToCheck: Date): boolean {
  const currentDate = new Date();
  const oneMonthLater = new Date();


  // Set oneMonthLater to exactly one month from the current date
  oneMonthLater.setMonth(currentDate.getMonth() + 1);

  console.log("oneMonthLater", oneMonthLater);

  // Compare the provided date with oneMonthLater
  return dateToCheck > oneMonthLater;
}


  timeSlotsArr = [];
  slotDay: any;
  removeSlotArr = [];
  subArrNew: any = [];
  courtArr: any = [];
  mainserviceArr: any = [];
  mainserviceArrFinal: any = [];
  filnalCourtArr: any = [];
  newRecord: boolean;
  newSubRecord: boolean = true;
  isSlotBooked: boolean = false;
  finalSlotsArr = [];
  isVisible: boolean = true;
  isSelectedSlot: boolean = false;
  summaryPanel: any;


  deepSearch (object, key, predicate) {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (Object.keys(object)[i] === key && value != null) {
        let o = object[Object.keys(object)[i]];
        if (o != null) return o
      }
    }
    return null
  }

  getSelecteDropdownText(prod_ID) {

    for(var i = 0; i < this.products.length; i++){
      if(prod_ID == this.products[i].id){
        var productIndex = i;
      }
    }
    console.log("i", productIndex);
    return productIndex;

  }

  onChangeService(service) {
    console.log("service : ", service);
    //this.thisProdId = service;
    this.newRecord = true
    this.selectedProductIndex = this.getSelecteDropdownText(service);
    this.selectedProduct = this.products[this.selectedProductIndex].productName;
    this.selectedServiceType = this.products[this.selectedProductIndex].productType;
    this.sessionType = this.products[this.selectedProductIndex].sessionType;
    if(this.selectedServiceType  == "Multi Session")
    {
      this.monthlyMultiBookingOption = this.products[this.selectedProductIndex].monthlyMultiBookingOption

      if( this.monthlyMultiBookingOption === "Selected Dates")
      {
        this.monthlyMultiBookingSelectedDates = this.parseSelectedDates(this.products[this.selectedProductIndex].monthlyMultiBookingSelectedDates);
        console.log("selected dates",this.monthlyMultiBookingSelectedDates );
        this.generateFutureDates();
      }
      else
      {
        this.monthlyMultiBookingSelectedDates.length = 0; //clear the array for other product types
      }
      console.log("MM Settings1 : ", this.monthlyMultiBookingOption );
      console.log("MM Settings2 : ", this.monthlyMultiBookingSelectedDates );

    }
    console.log("onChangeService Selected service", this.products[this.selectedProductIndex]);
    // Get slots by time sorted
    this.getTimeWiseSlots(this.id, this.dateChange, this.products[this.selectedProductIndex].id, this.selectedProduct, this.products[this.selectedProductIndex].productType);

  }



  onStartDateChange(startDate: string) {
    console.log('Selected session count:', startDate);
    this.selectedStartDate = startDate;
    console.log('selectedStartDate', this.selectedStartDate);
    // Handle the change in session count as needed
  }
  setMinDate() {
      const today = new Date();
      this.minDate = today.toISOString().split('T')[0]; // Format: 'yyyy-MM-dd'
  }

  // Get time string convert into time
  convertStringTimetoDateTime(dStr,format) {
    var now = new Date();
    var newNow;
    if (format == "h:m") {
       now.setHours(dStr.substr(0,dStr.indexOf(":")));
       now.setMinutes(dStr.substr(dStr.indexOf(":")+1));
       now.setSeconds(0);
       newNow = now.getHours()+":"+now.getMinutes()+"0";
       return newNow;
    }else
      return "Invalid Format";
  }

  onDateChange(dateChange) {
    this.dateChange = dateChange;
    //this.selectedProductIndex = this.getSelecteDropdownText(this.thisProdId);
    console.log("this.selectedProductIndex : ", this.selectedProductIndex );
    console.log("this.products : ", this.products);
    console.log("onDateChange", this.products[this.selectedProductIndex]);
    console.log("selected service type : ", this.products[this.selectedProductIndex].productType);
    // Get slots by time sorted
    //this.getTimeWiseSlots(this.id, dateChange, this.thisProdId, '', this.products[this.selectedProductIndex].productType);
    this.getTimeWiseSlots(this.id, dateChange, this.products[this.selectedProductIndex].id, '', this.products[this.selectedProductIndex].productType);

  }

  clearDate() {
      this.toDate = '';

      console.log("Clear Date");
  }

  // format date in typescript
  getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  round(value, decimals) {
    return Number(Math.round(Number(value) + Number(decimals)) +'e-'+ decimals).toFixed(decimals);
  }


  element2: any;
  timeSlot: any;
  removeSlotItem(event, price, slotIndex): void {
      var thisId = event.currentTarget.id;
      var slotIds = thisId.split("_");
      this.removeSlotArr.shift();
      this.element2 = document.getElementById('slotRow_'+slotIndex);
      this.timeSlot = document.getElementById('slot_'+slotIds[1]);
      this.element2.classList.add('slot_deleted');
      this.timeSlot.classList.remove('slot_selected');

      var yorBookingEle = document.getElementById('your_booking');


      if(this.removeSlotArr.length == 0){
        yorBookingEle.classList.remove('slot_selected');
      }

      this.totalSum = Number(this.totalSum) - Number(price);
      if(this.totalSum == 0){
        this.isTotalSumZero = false;
      }

      this.bookedslotsLength = this.removeSlotArr.length;
      if(this.removeSlotArr.length == 0){
        this.timeSlotsArr = [];
      }
  }



  bookSlots(event, selectValue, selectService): void {

    let user = JSON.parse(sessionStorage.getItem('current-user-context'));

    let singleObj = {
      startTime: this.finalStartTime,
      endTime: this.finalEndTime,
      bookingDate: this.slotDate,
      amount: this.totalSum,
      category: "Placeholder",
      tenantId: Number(this.id),
      venueId: this.venueId,
      userId: user.userId,
      productId: this.productId,
      advanceProductId: 1
    }


    // this.authService.singleBookingRequestToServer(singleObj, 1).subscribe(
    //   (response) => {
    //

    //     this.singleBooking = response;
    //   },
    //   (error) => {

    //   }
    // );-
  }

  numberLength(number) {
    let length = 0;
    let n = Math.abs(number);
    do {
      n /= 10;
      length++;
    } while (n >= 1);
    return length;
  }

  selectDays(event){
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

  showModal():void {
    $("#myModal").modal('show');
    setTimeout(() => {
      $(".modal-backdrop").remove();
    }, 100);
  }

  hideModal():void {
    // document.getElementById('close-modal').click();
    $("#myModal").modal('hide');
  }

  closeModal():void {
      // document.getElementById('close-modal').click();
      $("#myModal").modal('close');
    }

  unique = (value, index, self) => {
    return self.indexOf(value) === index
  }
  timeTo12HrFormat(time){   // Take a time in 24 hour format and format it in 12 hour format
      var time_part_array = time.split(":");
      var ampm = 'AM';

      if (time_part_array[0] >= 12) {
          ampm = 'PM';
      }

      if (time_part_array[0] > 12) {
          time_part_array[0] = time_part_array[0] - 12;
      }

      var formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;

      return formatted_time;
  }
  // get time wise slots array combined

  getTimeWiseSlots(id, dateChange, thisProdId, selectText, serviceType) {

    var arraySlotsAndType: any = [];

    this.allSlots = [];
    var combineSlots: any = [];
    var toCompare = new Date(`1970-01-01T12:00:00`);
    var toCompare6 = new Date(`1970-01-01T18:00:00`);
    this.dateChange = dateChange;
    console.log("getTimeWiseSlots", serviceType);
    //this.authService.getSlotsSauByTenantId(id, this.isSelectedCat, dateChange, thisProdId).subscribe(

    const dateToCheck = new Date(this.dateChange);
    console.log("4 This Datechange", this.dateChange);
    console.log("4 dateToCheck", dateToCheck);
    console.log("4 TrueorFalse", this.checkIfDateIsLaterThanMonth(dateToCheck));
    if(this.checkIfDateIsLaterThanMonth(dateToCheck))
    {
      this.allSlots.length = 0;
      console.log("Slots Cleared");
    }
    else
    {
      this.authService.getSlotsSauByTenantId(id, dateChange, thisProdId, serviceType).subscribe(
      (response) => {
        this.allSlots = response;
        console.log("allslots : ", this.allSlots);
        for(var i = 0; i < this.allSlots.length; i++){
          var before12 = [];
          var bwtn12To6 = [];
          var after6 = [];
          if(this.allSlots[i].timeslotWiseProducts.length > 0){

              for(var j=0; j < this.allSlots[i].timeslotWiseProducts.length; j++){
                if(!arraySlotsAndType.includes(this.allSlots[i].venueName)){
                  arraySlotsAndType.push(this.allSlots[i].venueName);
                  var newObj = {
                    mainService: selectText,
                    type: this.allSlots[i].venueName,
                    // timeslotWiseProducts: new Object,
                  }
                }
                //console.log("newobj : ", newObj);
                if(arraySlotsAndType.includes(this.allSlots[i].venueName)){
                  const parts = this.allSlots[i].timeslotWiseProducts[j].startTime.split(':');
                  const hour = parts[0].padStart(2, '0');
                  const minute = parts[1];
                  const temp = new Date(`1970-01-01T${hour}:${minute}:00`);
                  //console.log( temp + "and" + toCompare + toCompare6);
                  if(temp < toCompare){
                    this.allSlots[i].timeslotWiseProducts[j].startTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].startTime);
                    this.allSlots[i].timeslotWiseProducts[j].endTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].endTime);
                    //console.log("selected : ",this.allSlots[i].timeslotWiseProducts[j].selected);
                    //console.log("Adding " + this.allSlots[i].timeslotWiseProducts[j].startTime + "to before12");
                    //console.log("uid",this.allSlots[i].timeslotWiseProducts[j].uniqueId);
                    //console.log("selectedslotMap", this.selectedSlotsMap);
                    //console.log("Conv Time: ", this.allSlots[i].timeslotWiseProducts[j].startTime);
                    before12.push(this.allSlots[i].timeslotWiseProducts[j]);
                    newObj['before12'] = before12;
                    //console.log("before12: ", before12);
                    // newObj.timeslotWiseProducts['before12'] = before12.filter(this.unique);
                  } else if(temp >= toCompare && temp < toCompare6){
                    console.log("Adding " + this.allSlots[i].timeslotWiseProducts[j].startTime + "to bwtn12To6");
                    this.allSlots[i].timeslotWiseProducts[j].startTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].startTime);
                    this.allSlots[i].timeslotWiseProducts[j].endTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].endTime);
                    bwtn12To6.push(this.allSlots[i].timeslotWiseProducts[j]);
                    newObj['bwtn12To6'] = bwtn12To6;
                    // newObj.timeslotWiseProducts['bwtn12To6'] = bwtn12To6.filter(this.unique);
                  } else {
                    console.log("Adding " + this.allSlots[i].timeslotWiseProducts[j].startTime + "to after6");
                    this.allSlots[i].timeslotWiseProducts[j].startTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].startTime);
                    this.allSlots[i].timeslotWiseProducts[j].endTime = this.timeTo12HrFormat(this.allSlots[i].timeslotWiseProducts[j].endTime);
                    after6.push(this.allSlots[i].timeslotWiseProducts[j]);
                    newObj['after6'] = after6;
                    // newObj.timeslotWiseProducts['after6'] = after6.filter(this.unique);
                  }

                }

              }

              combineSlots.push(newObj);
          }

        }

        // this.allSlots = response;
        this.allSlots = combineSlots;
        this.allSlotsLength = (this.allSlots.length > 0) ? true : false;
        //

        // return combineSlots;
      },
      (error) => {

        }
     );
    }
  }

  cancelSlots(): void {
    this.mainserviceArrFinal = [];
    this.router.navigate(['/product-list/'+this.id]);
  }

  confirmBooking()
  {

    //console.log("Final Slot list", this.selectedSlotsMap);
    if (this.selectedSlotsMap.size == 0)
    {
      alert("Add slots to the cart");
    }
    else
    {
      var arrayJSon: string = '';
      for (const value of this.selectedSlotsMap.values())
      {
        //array_name.push(value);
         if (arrayJSon != '') {
            value.paymentType = "PhonePe"
            arrayJSon =  arrayJSon + ","  + JSON.stringify(value);
         }
         else{
            value.paymentType = "PhonePe"
            arrayJSon = JSON.stringify(value);
         }
      }
      arrayJSon = "[" +  arrayJSon + "]";
      console.log("Array json pl => ", arrayJSon);

      this.authService.initiatePayApi(arrayJSon).subscribe(
        (response: any) => {
          console.log("Initiate pay api response => ", response);
          const redirectUrl = response.txnToken;
          const confirmationId = response.confirmationId;
          sessionStorage.setItem("bookingObj", arrayJSon);
          window.location.href=redirectUrl;

          // const txtToken = response.txnToken;
          // const confirmationId = response.confirmationId;
          // const amount = response.amount;
          // console.log("txtToken: " + txtToken + "confirmationId: " + confirmationId);
          // this.initializeCheckout(confirmationId, txtToken, amount);
          // console.log("Initiate checkout done");
          // this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS);
          // this.authService.confirmBooking(arrayJSon).subscribe(
          //   (response: any) => {
          //         console.log("Booking response => ", response);

          //         this.hideModal();
          //         this.selectedSlotsMap.clear();
          //         //this.isBookingSuccessful = true;

          //         alert("Booking is successful: " + response.confirmationId);
          //         this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_PRODUCT_ALL);

          //   },
          //   (error) => {
          //      //this.isBookingSuccessful = false;

          //      console.log("CB Response: ", error);
          //      alert("Booking Failed: ");
          //   }

          // );
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if(error.error[0].bookingStatus == 'Unavailable') {
            this.hideModal();
            alert("selected slots are unavailable");
          }
        }
      );

      //DO NOT REMOVE: Used for Paytm Integration
      // this.authService.initiateTransaction(arrayJSon).subscribe(
      //   (response: any) => {
      //     console.log("Initiate Transaction response => ", response);
      //     const txtToken = response.txnToken;
      //     const confirmationId = response.confirmationId;
      //     const amount = response.amount;
      //     console.log("txtToken: " + txtToken + "confirmationId: " + confirmationId);
      //     this.initializeCheckout(confirmationId, txtToken, amount);
      //     console.log("Initiate checkout done");
      //     // this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS);
      //     // this.authService.confirmBooking(arrayJSon).subscribe(
      //     //   (response: any) => {
      //     //         console.log("Booking response => ", response);

      //     //         this.hideModal();
      //     //         this.selectedSlotsMap.clear();
      //     //         //this.isBookingSuccessful = true;

      //     //         alert("Booking is successful: " + response.confirmationId);
      //     //         this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_PRODUCT_ALL);

      //     //   },
      //     //   (error) => {
      //     //      //this.isBookingSuccessful = false;

      //     //      console.log("CB Response: ", error);
      //     //      alert("Booking Failed: ");
      //     //   }

      //     // );
      //   }
      // );
    }
  }
}

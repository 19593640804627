<!--<head>
  <meta name="viewport" content= "width=device-width, initial-scale=1.0">
</head>-->

<div class="main-container" style="background-color: #ffffff;">
  <div class="container" style= "min-height:600px; background-color: #ffffff; margin-top: 10px;">
    <div class="row" >
        <div class="col">
            <div style="border-bottom: 1px solid #B8B0B0; padding-left: 5px; padding-right: 5px;">
                <h1 style="font-size: 22px;color: #14274e;width: auto;margin-top: 20px;">
                    All Services
                </h1>
                <button class="btn btn-primary" type="button" (click)="clickAddNewProduct()" [disabled]="showAddNewProductForm" style="float: right;background-color: #ffffff;font-size: 14px;color: #14274e;margin-top: -45px;border-color: #14274E;">
                    Add New
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class= "dexterGrid" style="margin-top:20px;">
                <ag-grid-angular
                style="height: 250px; width: 100%;"
                class="ag-theme-alpine"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                >
                </ag-grid-angular>
            </div>
        </div>
    </div>

    <div *ngIf="showUpdateProductForm">
        <div class="row">
            <div class="col">
                <form [formGroup]="updateproductFormGroup">
                    <div class="form-group">
                        <div>
                            <p class= "admin-string-primary" style="margin-top: 20px;">
                                EDIT SERVICE
                                <button class="btn btn-primary" (click)="onUpdateProductFormSaveClicked()" type="button" [disabled]="updateproductFormGroup.invalid" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
                                    Save
                                </button>
                                <button class="btn btn-primary" (click)="onUpdateProductFormCancelClicked()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
                                    Cancel
                                </button>
                            </p>
                            <hr style="background-color: #D8DFE6;" />
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-name">
                                            SERVICE NAME
                                        </label>
                                        <input type="text" id="update-product-name" class="form-control" formControlName="updateProductNameFormControl" placeholder="Service Name"
                                            style="width: 350px;height: 40px;" />
                                        <div *ngIf="updateProductFormControls.updateProductNameFormControl.hasError('required') && updateProductFormControls.updateProductNameFormControl.touched" style="color: red;font-size: 14px;">
                                                Service Name is required.
                                        </div>
                                    </div>

                                    <div style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-description">
                                            SERVICE DESCRIPTION
                                        </label>
                                        <textarea id="update-product-description" class="form-control" formControlName="updateProductDescriptionFormControl" placeholder="Service Description" rows="2"
                                            style="width: 350px;">
                                        </textarea>
                                        <div *ngIf="updateProductFormControls.updateProductDescriptionFormControl.hasError('required') && updateProductFormControls.updateProductDescriptionFormControl.touched" style="color: red;font-size: 14px;">
                                            Service Description is required.
                                        </div>
                                    </div>
                                    <!-- <div style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-id">
                                            PRODUCT ID
                                        </label>
                                        <input disabled type="text" id="product-id" class="form-control" placeholder="Product ID"
                                            style="width: 350px;height: 40px;" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <!-- <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                            RATE
                                        </label>
                                            <div style="margin-bottom: 10px;">
                                                <mat-chip-list aria-label="Product Rate">
                                                    <mat-chip
                                                        *ngFor="let rate of rates"
                                                        [removable]="removable"
                                                        (removed)="deleteRate(rate)">
                                                        {{rate}}
                                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <div style="width: 350px;height: 40px;">
                                                <div *ngIf="showAddNewRatesButton">
                                                    <button class="btn btn-primary" type="button" (click)="clickedAddNewRatesButton()" style="width: 97px;height: 40px;font-size: 14px;color: #14274E; background-color: #FFFFFF;">
                                                        Add New
                                                    </button>
                                                </div>
                                                <div *ngIf="!showAddNewRatesButton">
                                                    <form [formGroup]="rateFormGroup">
                                                        <p style="font-size: 14px;">
                                                            Add Your Own
                                                            <input type="text" formControlName="rateFormControl" style="margin-left: 30px; width: 100px;/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;" />
                                                            <button class="btn btn-primary" type="button" [disabled]="rateFormGroup.invalid" (click)="createNewRate()" style="margin-left: 30px;width: 42px;height: 24px;font-size: 12px;color: #7c7575;background-color: #dde4e9;padding: 0px;">
                                                                Save
                                                            </button>
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                    </div>
                                -->

                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-rate">
                                            RATE
                                        </label>
                                        <input type="text" id="update-product-rate" class="form-control" formControlName="updateProductRateFormControl" placeholder="Service Rate"
                                            style="width: 350px;height: 40px;" />
                                        <div *ngIf="updateProductFormControls.updateProductRateFormControl.hasError('required') && updateProductFormControls.updateProductRateFormControl.touched" style="color: red;font-size: 14px;">
                                            Service Rate is required.
                                        </div>
                                    </div>

                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-duration">
                                            DURATION
                                        </label>
                                        <div style="display: flex;">
                                            <input disabled type="number" id="update-product-duration" formControlName="updateProductDurationFormControl" class="form-control" style="width: 150px;height: 40px; " />
                                            <span style="margin-left: 15px;margin-top: 5px;">mins</span>
                                        </div>
                                        <!-- <div *ngIf="updateProductFormControls.updateProductDurationFormControl.hasError('required') && updateProductFormControls.updateProductDurationFormControl.touched" style="color: red;font-size: 14px;">
                                            Product Duration is required.
                                        </div> -->
                                    </div>
                                    <!--  -->
                                    <div *ngIf="isClickedProductSingleSession() || isClickedMultiSessionSessions()" style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-capacity-per-venue">
                                            CAPACITY PER VENUE
                                        </label>
                                        <div style="display: flex;">
                                            <input type="number" id="update-product-capacity-per-venue" formControlName="updateProductCapacityPerVenueFormControl" class="form-control" style="width: 150px;height: 40px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-type">
                                            TYPE
                                        </label>
                                        <div style="width: 350px;height: 40px; margin-top: 5px;">
                                            <mat-chip-list aria-label="Product Type">
                                                <mat-basic-chip *ngIf="isClickedProductSingleSession()" id="single-session" style="margin-left: 10px; cursor: pointer; padding: 5px; background-color: #DDE4E9; border: 1px solid #DAE2E8; border-radius: 4px;">Single Booking</mat-basic-chip>
                                                <mat-basic-chip *ngIf="isClickedProductMultiSession()" id="multi-session" style="margin-left: 10px; cursor: pointer; padding: 5px; background-color: #DDE4E9; border: 1px solid #DAE2E8; border-radius: 4px;">Multiple Bookings</mat-basic-chip>
                                            </mat-chip-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedMultiSessionSessions()">
                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-sub-type-multi-sessions">
                                            SUB TYPE
                                        </label>
                                        <div style="display: flex;">
                                            <input disabled id="update-product-sub-type-multi-sessions" formControlName="updateProductMultiSessionSessionsFormControl" class="form-control" style="width: 150px;height: 40px; " />
                                        </div>
                                    </div>
                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-sub-type-multi-sessions-no">
                                            NO OF SESSIONS
                                        </label>
                                        <div style="display: flex;">
                                            <input id="update-product-sub-type-multi-sessions-no" formControlName="updateProductMultiSessionSessionsNoFormControl" class="form-control" style="width: 150px;height: 40px; " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedMultiSessionSessions()">
                                    <div *ngFor="let session of clickedProductDetails.sessions; let i = index" style="margin-top: 20px;">
                                        <div>
                                            <div style="float: left;margin-right: 100px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="session-occurence">
                                                    OCCURENCE
                                                </label>
                                                <div style="display: flex;">
                                                    <span style="margin-left: 15px;margin-top: 15px; margin-right: 20px;">Session</span>
                                                    <mat-chip-listbox style="min-width: 400px; margin-top: 15px;" multiple>
                                                        <mat-chip-option class="chip_margin" *ngFor="let chip of chips" selectable="false" [selected]="isSessionSelectedOnGivenDay(chip, i)">
                                                            <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                        </mat-chip-option>
                                                    </mat-chip-listbox>
                                                </div>
                                            </div>

                                            <div style="float: left; margin-right: 30px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product">
                                                        START TIME
                                                </label>
                                                <input disabled [(ngModel)]="session.startTime" [ngModelOptions]="{standalone: true}" id="sessionStartTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-right: 50px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                                        END TIME
                                                </label>
                                                <input disabled [(ngModel)]="session.endTime" [ngModelOptions]="{standalone: true}" id="sessionEndTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-top: 35px;">
                                                <div style="width: 50px;">
                                                    <!-- <span class="rupee" (click)="clickedAdvancedPricing(0)"></span> -->
                                                    <span (click)="clickedDeleteSessionEditProduct(session)" style="display: inline-block; cursor: pointer;">
                                                        <mat-icon>delete</mat-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedMultiSessionMonthly()">
                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-sub-type-multi-monthly">
                                            SUB TYPE
                                        </label>
                                        <div style="display: flex;">
                                            <input disabled id="update-product-sub-type-multi-monthly" formControlName="updateProductMultiSessionMonthlyFormControl" class="form-control" style="width: 150px;height: 40px; " />
                                        </div>
                                    </div>
                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-sub-type-multi-sessions-no">
                                            NO OF MONTHS
                                        </label>
                                        <div style="display: flex;">
                                            <input id="update-product-sub-type-multi-monthly-no" formControlName="updateProductMultiSessionMonthlyNoFormControl" class="form-control" style="width: 150px;height: 40px; " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedMultiSessionMonthly()" style="margin-top: 20px;">
                                        <input type="radio" checked/>
                                        <label style="font-size: 13px; margin-left: 5px;">{{clickedProductDetails.monthlyMultiBookingOption}}</label>
                                </div>
                                <p *ngIf="selectedOptionForMonthlyMultiBooking === radioOptionsSetForMonthlyMultiBooking[1]" style="font-size: 14px;">
                                    <input type="text" formControlName="updateSelectedDatesFormControl" style="/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;width: 400px;" />
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedMultiSessionMonthly()">
                                    <div *ngFor="let session of clickedProductDetails.sessions; let i = index" style="margin-top: 20px;">
                                        <div style="float: left;margin-right: 100px;">
                                            <div style="float: left;margin-right: 100px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="session-occurence">
                                                    OCCURENCE
                                                </label>
                                                <div style="display: flex;">
                                                    <span style="margin-left: 15px;margin-top: 5px; margin-right: 20px;">Session</span>
                                                    <mat-chip-list style="width: 320px;" multiple>
                                                        <mat-chip-option class="chip_margin" *ngFor="let chip of chips" selectable="false" [selected]="isSessionSelectedOnGivenDay(chip, i)">
                                                            <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                        </mat-chip-option>
                                                    </mat-chip-list>
                                                </div>
                                            </div>

                                            <div style="float: left; margin-right: 30px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product">
                                                        START TIME
                                                </label>
                                                <input disabled [(ngModel)]="session.startTime" [ngModelOptions]="{standalone: true}" id="sessionStartTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-right: 50px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                                        END TIME
                                                </label>
                                                <input disabled [(ngModel)]="session.endTime" [ngModelOptions]="{standalone: true}" id="sessionEndTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-top: 35px;">
                                                <div style="width: 100px;">
                                                    <!-- <span class="rupee" (click)="clickedAdvancedPricing(0)"></span> -->
                                                    <span (click)="clickedDeleteSessionEditProduct(session)" style="display: inline-block; cursor: pointer;">
                                                        <mat-icon>delete</mat-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="showMultipleBookingsOption">
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-sub-type">
                                            SUB TYPE
                                        </label>
                                        <select [(ngModel)]="selectedProductSubTypeValue" id="product-sub-type" formControlName="selectedProductSubTypeValue" class="form-control" (change)="selectValueChangeForProductSubType()" required>
                                            <option *ngFor="let productSubType of productSubTypes" [value]="productSubType">
                                                    {{productSubType}}
                                            </option>
                                        </select>
                                    </div>
                                    <div style="float: left;">
                                        <div *ngIf="selectedProductSubTypeValue === productSubTypes[0]">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="frequency">
                                                No of Months
                                            </label>
                                            <select [(ngModel)]="selectedFrequencyValue" id="frequency" formControlName="selectedFrequencyValue" class="form-control" (change)="selectValueChangeForProductSubType()">
                                                <option *ngFor="let month of months" [value]="month">
                                                        {{month}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="selectedProductSubTypeValue === productSubTypes[1]">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="frequency">
                                                No of Sessions
                                            </label>
                                            <input [(ngModel)]="selectedFrequencyValue" formControlName="selectedFrequencyValue" type="number" id="duration" (change)="inputValueChangeForSessionsFrequency()" class="form-control" style="width: 100px;height: 40px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="isClickedProductSingleSession()">
                                  <label style="font-size: 11px;height: 15px;padding-top: 10px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="session-occurence">
                                    OCCURENCE
                                  </label>
                                    <div *ngFor="let session of clickedProductDetails.sessions; let i = index" style="margin-top: 5px;">
                                        <div style="float: left;margin-top: 10px;">
                                            <div style="float: left;margin-top: 10px;">
                                                <div style="display: flex;">
                                                    <span style="margin-left: 10px; margin-top: 15px; margin-right: 20px;">Session</span>
                                                    <mat-chip-listbox  style="min-width: 400px; margin-top: 15px;" multiple>
                                                        <mat-chip-option class="chip_margin" *ngFor="let chip of chips" selectable="false" [selected]="isSessionSelectedOnGivenDay(chip, i)"
                                                        style="font-size:11 px;">
                                                            <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                        </mat-chip-option>
                                                    </mat-chip-listbox>
                                                </div>
                                            </div>

                                            <div style="float: left; margin-left: 10px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product">
                                                        START TIME
                                                </label>
                                                <!-- <select #selectStartTime required id="start-time-new-product" formControlName="startTimeForSingleSessionFormContol" (change)="selectValueChangeForStartTimeMultiBooking(selectStartTime.value, 0)" class="form-control" style="width: 120px">
                                                        <option *ngFor="let startTimeOption of startTimeOptions" [value]="startTimeOption">
                                                                {{startTimeOption}}
                                                        </option>
                                                </select> -->
                                                <input disabled [(ngModel)]="session.startTime" [ngModelOptions]="{standalone: true}" id="sessionStartTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-left: 10px;">
                                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                                        END TIME
                                                </label>
                                                <!-- <select #selectEndTime required id="facility-type" (change)="selectValueChangeForEndTimeMultiBooking(selectEndTime.value, 0)" class="form-control" style="width: 120px">
                                                        <option *ngFor="let endTimeOption of endTimeOptions" [value]="endTimeOption">
                                                                {{endTimeOption}}
                                                        </option>
                                                </select> -->
                                                <input disabled [(ngModel)]="session.endTime" [ngModelOptions]="{standalone: true}" id="sessionEndTime" class="form-control" style="width: 120px"/>
                                            </div>

                                            <div style="float: left; margin-top: 35px;">
                                                <div style="width: 50px;">
                                                    <!-- <span class="rupee" (click)="clickedAdvancedPricing(0)"></span> -->
                                                    <span (click)="clickedDeleteSessionEditProduct(session)" style="display: inline-block; cursor: pointer;">
                                                        <mat-icon>delete</mat-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngFor="let i of noOfSessionAddedInProductEditButNotSubmit | fill; let ind = index">
                                        <div style="float: left;margin-right: 85px;">
                                             <div style="display: flex;">
                                                <span style="margin-left: 15px;margin-top: 15px; margin-right: 20px;">Session {{ind +1}} </span>
                                                <mat-chip-listbox style="min-width=400px; margin-top: 15px;" multiple>
                                                    <mat-chip-option class="chip_margin" *ngFor="let chip of productEditAddSessionChips" selectable="true" [selected]="isSelectedSingleBookingForEditProductAddSession(chip, ind)" (click)="onClickSingleBookingChipsEditProductAddSession($event, chip, ind)">
                                                        <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </div>
                                        </div>

                                        <div style="float: left; margin-right: 30px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-edit-product-single-booking-add-session">
                                                    START TIME
                                            </label>
                                            <select #selectStartTimeSingleBookingEditProduct required id="start-time-edit-product-single-booking-add-session" formControlName="startTimeForSingleSessionEditProductFormContol" (change)="selectValueChangeForStartTimeSingleBookingEditProduct(selectStartTimeSingleBookingEditProduct.value, ind)" class="form-control" style="width: 120px">
                                                    <option *ngFor="let startTimeOption of startTimeOptions" [value]="startTimeOption">
                                                            {{startTimeOption}}
                                                    </option>
                                            </select>
                                        </div>

                                        <div style="float: left; margin-right: 50px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="end-time-edit-product-single-booking-add-session">
                                                    END TIME
                                            </label>
                                            <select #selectEndTimeMultiBookingEditProduct required id="end-time-edit-product-single-booking-add-session" formControlName="endTimeForSingleSessionEditProductFormContol" (change)="selectValueChangeForEndTimeSingleBookingEditProduct(selectEndTimeMultiBookingEditProduct.value, ind)" class="form-control" style="width: 120px">
                                                    <option *ngFor="let endTimeOption of endTimeOptions" [value]="endTimeOption">
                                                            {{endTimeOption}}
                                                    </option>
                                            </select>
                                        </div>

                                        <div style="float: left; margin-top: 35px;">
                                            <!-- <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold; margin-left: 10px;" for="session-actions">
                                                ACTIONS
                                            </label> -->
                                            <div style="width: 50px;">
                                                <span class="rupee" (click)="clickedAdvancedPricingSingleBookingEditProductAddSession(ind)"></span>
                                                <span *ngIf=" ind != 0" (click)="clickedDeleteActionsSingleBookingEditProduct(ind)" style="display: inline-block; cursor: pointer;">
                                                    <mat-icon>delete</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="isClickedProductSingleSession()">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 30px;">
                                        <button class="btn btn-primary" type="button" (click)="addSessionForProductEdit()" for="single-booking-add-new-session" style="background-color: #ffffff;color: #14274e;width: 100px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-left: 15px;border-color: #14274E;">
                                            Add Session
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 145px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-status">
                                            STATUS
                                        </label>
                                        <div style="display: flex;">
                                            <mat-slide-toggle
                                            [(ngModel)]="isChecked"
                                            [ngModelOptions]="{standalone: true}"
                                            class="example-margin"
                                            [color]="color"
                                            [checked]="checked"
                                            [disabled]="disabled">
                                            {{getProductStatusValue()}}
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 145px;">
                                        <button class="btn btn-primary" type="button" (click)="addSessionForSingleBooking(noOfSessionsToDisplay)" for="single-booking-add-new-session" style="background-color: #ffffff;color: #14274e;width: 100px;height: 30px;padding: 0px;font-size: 14px;float: right;border-color: #14274E;">
                                            Add Session
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </form>
            </div>
        </div>
    </div>

    <div *ngIf="showAddNewProductForm">
        <div class="row">
            <div class="col">
                <form [formGroup]="productFormGroup">
                    <div class="form-group">
                        <div>
                            <p class="admin-string-primary" style="margin-top: 20px">
                                NEW SERVICE
                                <button class="btn btn-primary" (click)="onCreateProductFormSaveClicked()" type="button" [disabled]="productFormGroup.invalid" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
                                    Save
                                </button>
                                <button class="btn btn-primary" (click)="onCreateProductFormCancelClicked()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
                                    Cancel
                                </button>
                            </p>
                            <hr style="background-color: #D8DFE6;" />
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-name">
                                            SERVICE NAME
                                        </label>
                                        <input type="text" id="product-name" class="form-control" formControlName="productNameFormControl" placeholder="Service Name"
                                            style="width: 350px;height: 40px;" />
                                        <div *ngIf="productFormControls.productNameFormControl.hasError('required') && productFormControls.productNameFormControl.touched" style="color: red;font-size: 14px;">
                                                Service Name is required.
                                        </div>
                                    </div>

                                    <div style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-description">
                                            SERVICE DESCRIPTION
                                        </label>
                                        <textarea id="product-description" class="form-control" formControlName="productDescriptionFormControl" placeholder="Service Description" rows="2"
                                            style="width: 350px;">
                                        </textarea>
                                        <div *ngIf="productFormControls.productDescriptionFormControl.hasError('required') && productFormControls.productDescriptionFormControl.touched" style="color: red;font-size: 14px;">
                                            Service Description is required.
                                        </div>
                                    </div>
                                    <!-- <div style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-id">
                                            PRODUCT ID
                                        </label>
                                        <input disabled type="text" id="product-id" class="form-control" placeholder="Product ID"
                                            style="width: 350px;height: 40px;" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <!-- <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                            RATE
                                        </label>
                                            <div style="margin-bottom: 10px;">
                                                <mat-chip-list aria-label="Product Rate">
                                                    <mat-chip
                                                        *ngFor="let rate of rates"
                                                        [removable]="removable"
                                                        (removed)="deleteRate(rate)">
                                                        {{rate}}
                                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <div style="width: 350px;height: 40px;">
                                                <div *ngIf="showAddNewRatesButton">
                                                    <button class="btn btn-primary" type="button" (click)="clickedAddNewRatesButton()" style="width: 97px;height: 40px;font-size: 14px;color: #14274E; background-color: #FFFFFF;">
                                                        Add New
                                                    </button>
                                                </div>
                                                <div *ngIf="!showAddNewRatesButton">
                                                    <form [formGroup]="rateFormGroup">
                                                        <p style="font-size: 14px;">
                                                            Add Your Own
                                                            <input type="text" formControlName="rateFormControl" style="margin-left: 30px; width: 100px;/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;" />
                                                            <button class="btn btn-primary" type="button" [disabled]="rateFormGroup.invalid" (click)="createNewRate()" style="margin-left: 30px;width: 42px;height: 24px;font-size: 12px;color: #7c7575;background-color: #dde4e9;padding: 0px;">
                                                                Save
                                                            </button>
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                    </div>
                                -->

                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-rate">
                                            RATE
                                        </label>
                                        <input type="text" id="product-rate" class="form-control" formControlName="productRateFormControl" placeholder="Service Rate"
                                            style="width: 350px;height: 40px;" />
                                        <div *ngIf="productFormControls.productRateFormControl.hasError('required') && productFormControls.productRateFormControl.touched" style="color: red;font-size: 14px;">
                                            Service Rate is required.
                                        </div>
                                    </div>

                                    <div style="float: left; margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="duration">
                                            DURATION
                                        </label>
                                        <div style="display: flex;">
                                            <input type="number" id="duration" formControlName="productDurationFormControl" class="form-control" style="width: 150px;height: 40px;" />
                                            <span style="margin-left: 15px;margin-top: 5px;">mins</span>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div *ngIf="showSingleBookingsOption || selectedProductSubTypeValue === productSubTypes[1]" style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="capacity-per-venue">
                                            CAPACITY PER VENUE
                                        </label>
                                        <div style="display: flex;">
                                            <input type="number" [required]="isCapacityPerVenueRequired" id="capacity-per-venue" formControlName="productCapacityPerVenueFormControl" class="form-control" style="width: 150px;height: 40px;" />

                                        </div>
                                        <div *ngIf="getCapacityPerVenueRequired() && productFormControls.productCapacityPerVenueFormControl.touched" style="color: red;font-size: 14px;">
                                            Capacity Per Venue is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-type">
                                            TYPE
                                        </label>
                                        <div style="width: 350px;height: 40px; margin-top: 5px;">
                                            <mat-chip-list aria-label="Product Type">
                                                <mat-basic-chip id="single-session" style="margin-left: 10px; cursor: pointer; padding: 5px; background-color: #DDE4E9; border: 1px solid #DAE2E8; border-radius: 4px;" (click)="productTypeChipsClickedSingleBooking()">Single Booking</mat-basic-chip>
                                                <mat-basic-chip id="multi-session" style="margin-left: 10px; cursor: pointer; padding: 5px; background-color: #DDE4E9; border: 1px solid #DAE2E8; border-radius: 4px;"(click)="productTypeChipsClicked2()">Multiple Bookings</mat-basic-chip>
                                            </mat-chip-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="showMultipleBookingsOption">
                                    <div style="float: left;margin-right: 100px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-sub-type">
                                            SUB TYPE
                                        </label>
                                        <select [(ngModel)]="selectedProductSubTypeValue" id="product-sub-type" formControlName="selectedProductSubTypeValue" class="form-control" (change)="selectValueChangeForProductSubType()" required>
                                            <option *ngFor="let productSubType of productSubTypes" [value]="productSubType">
                                                    {{productSubType}}
                                            </option>
                                        </select>
                                    </div>
                                    <div style="float: left;">
                                        <div *ngIf="selectedProductSubTypeValue === productSubTypes[0]">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="frequency">
                                                No of Months
                                            </label>
                                            <select [(ngModel)]="selectedFrequencyValue" id="frequency" formControlName="selectedFrequencyValue" class="form-control" (change)="selectValueChangeForProductSubType()">
                                                <option *ngFor="let month of months" [value]="month">
                                                        {{month}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="selectedProductSubTypeValue === productSubTypes[1]">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="frequency">
                                                No of Sessions
                                            </label>
                                            <input [(ngModel)]="selectedFrequencyValue" formControlName="selectedFrequencyValue" type="number" id="duration" (change)="inputValueChangeForSessionsFrequency()" class="form-control" style="width: 100px;height: 40px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="selectedProductSubTypeValue === productSubTypes[0]" style="margin-top: 40px;">
                                    <div class="form-group">
                                        <div *ngFor="let option of radioOptionsSetForMonthlyMultiBooking">
                                            <span>
                                                <input type="radio"  [(ngModel)]="selectedOptionForMonthlyMultiBooking" [ngModelOptions]="{standalone: true}" [value]="option"/>
                                                <label style="font-size: 13px; margin-left: 5px;">{{option}}</label>
                                              </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="selectedOptionForMonthlyMultiBooking === radioOptionsSetForMonthlyMultiBooking[1]">
                                    <p style="font-size: 14px;">
                                        <input type="text" formControlName="selectedDatesFormControl" style="/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;width: 400px;" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div *ngIf="showMultipleBookingsOption && selectedProductSubTypeValue !== undefined" style="margin-top: 20px;">
                                    <div *ngFor="let i of noOfSessionsToDisplayForMultiBooking | fill; let ind = index">
                                         <div style="float: left;margin-right: 100px;">
                                            <!-- <div *ngIf="selectedProductSubTypeValue === productSubTypes[0]">
                                                <div style="display: flex;">
                                                    <span style="margin-left: 30px; margin-top: 30px; margin-right: 200px;">Session {{ind + 1}}</span>
                                                </div>
                                            </div> -->
                                            <!-- *ngIf="selectedProductSubTypeValue === productSubTypes[1]" -->
                                            <div>

                                                <div style="display: flex;">
                                                    <span style="margin-left: 15px;margin-top: 15px; margin-right: 20px;">Session {{ind + 1}}</span>
                                                    <mat-chip-listbox style="min-width=400px;margin-top: 15px;"multiple>
                                                        <mat-chip-option class="chip_margin" *ngFor="let chip of chips" selectable="true" [selected]="isSelectedMultiBooking(chip, ind)" (click)="onClickMultiBookingChips($event, chip, ind)">
                                                            <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                        </mat-chip-option>
                                                    </mat-chip-listbox>
                                                </div>
                                            </div>

                                        </div>

                                        <div style="float: left; margin-right: 30px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product">
                                                    START TIME
                                            </label>
                                            <select #selectStartTime required id="start-time-new-product" formControlName="startTimeForSingleSessionFormContol" (change)="selectValueChangeForStartTimeMultiBooking(selectStartTime.value, ind)" class="form-control" style="width: 120px">
                                                    <option *ngFor="let startTimeOption of startTimeOptions" [value]="startTimeOption">
                                                            {{startTimeOption}}
                                                    </option>
                                            </select>
                                        </div>

                                        <div style="float: left; margin-right: 50px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                                    END TIME
                                            </label>
                                            <select #selectEndTime required id="facility-type" formControlName="endTimeForSingleSessionFormContol" (change)="selectValueChangeForEndTimeMultiBooking(selectEndTime.value, ind)" class="form-control" style="width: 120px">
                                                    <option *ngFor="let endTimeOption of endTimeOptions" [value]="endTimeOption">
                                                            {{endTimeOption}}
                                                    </option>
                                            </select>
                                        </div>
                                        <!-- *ngIf="selectedProductSubTypeValue === productSubTypes[0]" -->
                                        <div style="float: left; margin-top: 35px;">
                                            <!-- <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold; margin-left: 10px;" for="session-actions">
                                                ACTIONS
                                            </label> -->
                                            <div style="width: 50px;">
                                                <span class="rupee" (click)="clickedAdvancedPricing(ind)"></span>
                                                <span *ngIf="false" (click)="clickedDeleteActionsMultiBooking(ind)" style="display: inline-block; cursor: pointer;">
                                                    <mat-icon>delete</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="showSingleBookingsOption" style="margin-top: 20px;">
                                    <div *ngFor="let i of noOfSessionsToDisplay | fill; let ind = index">
                                    <div style="float: left;margin-right: 20px;">

                                        <!-- <div style="display: flex;"> -->
                                            <!-- <span style="margin-left: 15px;margin-top: 5px; margin-right: 20px;">Session 1</span> -->
                                            <!-- <mat-chip-list multiple>
                                                <mat-chip class="chip_margin" *ngFor="let chip of chips" selectable="true" [selected]="isSelected(chip)" (click)="onClick($event, chip)">
                                                    <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div> -->
                                        <div style="display: flex;">
                                            <span style="margin-left: 15px;margin-top: 15px; margin-right: 20px;">Session {{ind +1}} </span>
                                            <mat-chip-listbox style="min-width=400px; margin-top: 15px;"multiple>
                                                <mat-chip-option class="chip_margin" *ngFor="let chip of chips" selectable="true" [selected]="isSelectedSingleBooking(chip, ind)" (click)="onClickSingleBookingChips($event, chip, ind)">
                                                    <span class="unselectableText">{{chip.charAt(0)}}</span>
                                                </mat-chip-option>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>

                                    <div style="float: left; margin-right: 30px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product-single-booking">
                                                START TIME
                                        </label>
                                        <select #selectStartTimeSingleBooking required id="start-time-new-product-single-booking" formControlName="startTimeForSingleSessionFormContol" (change)="selectValueChangeForStartTimeSingleBooking(selectStartTimeSingleBooking.value, ind)" class="form-control" style="width: 120px">
                                                <option *ngFor="let startTimeOption of startTimeOptions" [value]="startTimeOption">
                                                        {{startTimeOption}}
                                                </option>
                                        </select>
                                    </div>

                                    <div style="float: left; margin-right: 50px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="start-time-new-product-multi-booking">
                                                END TIME
                                        </label>
                                        <select #selectEndTimeMultiBooking required id="start-time-new-product-multi-booking" formControlName="endTimeForSingleSessionFormContol" (change)="selectValueChangeForEndTimeSingleBooking(selectEndTimeMultiBooking.value, ind)" class="form-control" style="width: 120px">
                                                <option *ngFor="let endTimeOption of endTimeOptions" [value]="endTimeOption">
                                                        {{endTimeOption}}
                                                </option>
                                        </select>
                                    </div>

                                    <div style="float: left; margin-top: 35px;">
                                        <!-- <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold; margin-left: 10px;" for="session-actions">
                                            ACTIONS
                                        </label> -->
                                        <div style="width: 50px;">
                                            <span class="rupee" (click)="clickedAdvancedPricingSingleBooking(ind)"></span>
                                            <span *ngIf=" ind != 0" (click)="clickedDeleteActionsSingleBooking(ind)" style="display: inline-block; cursor: pointer;">
                                                <mat-icon>delete</mat-icon>
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                    <!-- <div style="float: left;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold; margin-left: 10px;" for="session-actions">
                                                ACTIONS
                                        </label>
                                        <div style="width: 100px;">
                                            <span class="rupee" (click)="clickedAdvancedPricing()"></span>
                                            <span style="display: inline-block; cursor: pointer;">
                                                <mat-icon>delete</mat-icon>
                                            </span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="showSingleBookingsOption">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 30px;">
                                        <button class="btn btn-primary" type="button" (click)="addSessionForSingleBooking(noOfSessionsToDisplay)" for="single-booking-add-new-session" style="background-color: #ffffff;color: #14274e;width: 100px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-left: 15px;border-color: #14274E;">
                                            Add Session
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="showMultipleBookingsOption">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 30px;">
                                        <button class="btn btn-primary" type="button" (click)="addSessionForMultiBooking(noOfSessionsToDisplay)" for="multi-booking-add-new-session" style="background-color: #ffffff;color: #14274e;width: 100px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-left: 15px;border-color: #14274E;">
                                            Add Session
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div style="margin-top: 20px;">
                                    <div style="float: left;margin-right: 145px;">
                                        <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-status">
                                            STATUS
                                        </label>
                                        <div style="display: flex;">
                                            <mat-slide-toggle
                                            [(ngModel)]="isChecked"
                                            [ngModelOptions]="{standalone: true}"
                                            class="example-margin"
                                            [color]="color"
                                            [checked]="checked"
                                            [disabled]="disabled">
                                            {{getProductStatusValue()}}
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>

    <div *ngIf="!true">
        <div class="row">
            <!-- style="/*margin-left: 15px;*/" -->
            <div class="col">
                <div class="form-group">
                    <div>
                        <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                            Badminton
                            <button class="btn btn-primary" type="button" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
                                Save
                            </button>
                            <button class="btn btn-primary" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
                                Cancel
                            </button>
                        </p>
                        <hr style="background-color: #D8DFE6;" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <div style="float: left;margin-right: 100px;">
                                    <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                        SERVICE NAME
                                    </label>
                                    <input type="text" id="product-name" class="form-control" placeholder="Product Name"
                                        style="width: 350px;height: 40px;" />
                                    </div>
                                <div style="float: left;">
                                    <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-id">
                                        SERVICE ID
                                    </label>
                                    <input disabled type="text" id="product-id" class="form-control" placeholder="Product ID"
                                        style="width: 350px;height: 40px;" />
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                                    Address
                                </p>
                                <hr style="background-color: #D8DFE6;" />
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                                ADDRESS LINE 1
                                            </label>
                                            <input type="text" id="facility-name" class="form-control"
                                                placeholder="Facility Name" style="width: 350px;height: 40px;" />
                                            </div>
                                        <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                                ADDRESS LINE 2
                                            </label>
                                            <input type="text" id="facility-name" class="form-control"
                                                placeholder="Facility Name" style="width: 350px;height: 40px;" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div style="margin-top: 20px;">
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                                CITY
                                            </label>
                                            <input type="text" id="facility-name" class="form-control" placeholder="Facility Name"
                                                style="width: 350px;height: 40px;" />
                                            </div>
                                        <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                                PINCODE
                                            </label>
                                            <input type="text" id="facility-name" class="form-control" placeholder="Facility Name"
                                                style="width: 350px;height: 40px;" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                                    Photos
                                </p>
                                <hr style="background-color: #D8DFE6;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 14px; color: #7C7575;">Advance Pricing</h4>
    </div>

    <div class="modal-body">
        <div class="row" *ngFor="let timeSlot of timeSlotsToShowInModalPopup; let j = index; last as isLast;">
            <div *ngIf="!isLast">
                <div class="column">
                    <h6 style="font-size: 11px; color: #7C7575;">START TIME</h6>
                    <!-- <input [(ngModel)]="selectedFrequencyValue" formControlName="selectedFrequencyValue" type="number" id="duration" (change)="inputValueChangeForSessionsFrequency()" class="form-control" style="width: 100px;height: 40px;" /> -->
                    <input class="form-control" value={{timeSlot}} disabled/>
                </div>

                <div class="column">
                    <h6 style="font-size: 11px; color: #7C7575;">END TIME</h6>
                    <input class="form-control" value={{timeSlotsToShowInModalPopup[getNextIndexForTimeSlotsInModalPopup(j)]}} disabled/>
                </div>

                <div class="column" *ngIf="showMultipleBookingsOption">
                    <h6 style="font-size: 11px; color: #7C7575;">COST</h6>
                    <input type="number" [(ngModel)]="advancePricingForMultiBooking[advanceModalPopupOpenedIndex][j]" id="cost" class="form-control" />
                </div>

                <div class="column" *ngIf="showSingleBookingsOption">
                    <h6 style="font-size: 11px; color: #7C7575;">COST</h6>
                    <input type="number" [(ngModel)]="advancePricingForSingleBooking[advanceModalPopupOpenedIndexSingleBooking][j]" id="cost" class="form-control" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="showMultipleBookingsOption">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->

        <button class="btn btn-primary" (click)="onAdvancePricingCancelClicked()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="onAdvancePricingSaveClicked()" type="button" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
            Save
        </button>
    </div>

    <div class="modal-footer" *ngIf="showSingleBookingsOption">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->

        <button class="btn btn-primary" (click)="onAdvancePricingCancelClickedSingleBooking()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="onAdvancePricingSaveClickedSingleBooking()" type="button" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
            Save
        </button>
    </div>
</ng-template>

<ng-template #contentEditProduct let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 14px; color: #7C7575;">Advance Pricing</h4>
    </div>

    <div class="modal-body">
        <div class="row" *ngFor="let timeSlot of timeSlotsToShowInModalPopupEditProduct; let j = index; last as isLast;">
            <div *ngIf="!isLast">
                <div class="column">
                    <h6 style="font-size: 11px; color: #7C7575;">START TIME</h6>
                    <input class="form-control" value={{timeSlot}} disabled/>
                </div>

                <div class="column">
                    <h6 style="font-size: 11px; color: #7C7575;">END TIME</h6>
                    <input class="form-control" value={{timeSlotsToShowInModalPopupEditProduct[getNextIndexForTimeSlotsInModalPopup(j)]}} disabled/>
                </div>

                <div class="column">
                    <h6 style="font-size: 11px; color: #7C7575;">COST</h6>
                    <input type="number" [(ngModel)]="advancePricingForSingleBookingEditSession[advanceModalPopupOpenedIndexSingleBookingEditProduct][j]" id="cost" class="form-control" />
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onAdvancePricingCancelClickedSingleBookingEditProduct()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="onAdvancePricingSaveClickedSingleBooking()" type="button" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
            Save
        </button>
    </div>
</ng-template>

<ng-template #advancePricingModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 14px; color: #7C7575;">Alert</h4>
    </div>

    <div class="modal-body">
        <div class="row">
            On clicking okay advance pricing is overwritten.
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->

        <button class="btn btn-primary" (click)="onCancelClick()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="onOKClick()" type="button" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
            Okay
        </button>
    </div>
</ng-template>

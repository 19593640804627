<div class="main-container" style="background-color: #ffffff;">
  <div class="container" style="min-height: 600px; background-color: #ffffff; margin-top: 10px;">
    <div class="row">
      <div class="col-12 col-md-4 form-group" [ngClass]="{'mt-2': isMobile}">
        <label class="fieldName" style="font-size: 12px;">START DATE</label>
        <div class="input-group">
          <input type="date" [(ngModel)]="selectedStartDateStr" class="form-control" placeholder="Select Date"
            value="{{selectedStartDateStr}}" name="selectedStartDate" (change)="onStartDateChange($event.target.value)">
        </div>
      </div>
      <div class="col-12 col-md-4 form-group" [ngClass]="{'mt-2': isMobile}">
        <label class="fieldName" style="font-size: 12px;">END DATE</label>
        <div class="input-group">
          <input type="date" [(ngModel)]="selectedEndDateStr" class="form-control" placeholder="Select Date"
            value="{{selectedEndDateStr}}" name="selectedEndDate" (change)="onEndDateChange($event.target.value)">
        </div>
      </div>
      <div class="col-12 col-md-4 form-group search-group" [ngClass]="{'mt-2': isMobile}">
        <label class="fieldName" style="font-size: 12px;">SEARCH</label>
        <div class="row" style="margin-bottom: 10px; margin-left: 5px;">
          <input type="text" [(ngModel)]="searchText" placeholder="Search" class="form-control"
            style="min-width: 200px;">
          <button class="btn btn-primary book-now-btn" style="margin-left: 10px;" (click)="search()">Search</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col transaction-table">
        <div class="table-container">
          <table mat-table [dataSource]="data" class="example-table" matSort *ngIf="this.roleId !== 3" >
          <ng-container matColumnDef="bookingId">
            <th mat-header-cell *matHeaderCellDef>Booking ID</th>
            <td mat-cell *matCellDef="let row">{{row.bookingId}}</td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef>Customer</th>
            <td mat-cell *matCellDef="let row">{{row.userName}}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">{{getFormattedDateWithTime(row.bookingDateTime)}}</td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">{{row.totalAmount}}</td>
          </ng-container>

          <ng-container matColumnDef="payment">
            <th mat-header-cell *matHeaderCellDef>Payment</th>
            <td mat-cell *matCellDef="let row">{{row.paymentStatus}}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let row">{{row.userPhone}}</td>
          </ng-container>

          <ng-container matColumnDef="booking">
            <th mat-header-cell *matHeaderCellDef>
              Booking
            </th>
            <td mat-cell *matCellDef="let row">{{row.bookingStatus}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></tr>
        </table>
        </div>

        <mat-paginator #paginator [length]="resultsLength"
                        [pageSizeOptions]="[20,50,100]"
                        [showFirstLastButtons]="true" [pageSize]="this.pageSize" *ngIf="this.roleId !== 3"></mat-paginator>

        <div class="transactionGrid" style="margin-top: 20px;" *ngIf="this.roleId == 3">
          <ag-grid-angular style="height: 200px; width: 100%;" class="ag-theme-alpine" [rowData]="rowData"
            [columnDefs]="gridOptions.columnDefs" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)"
            [gridOptions]="gridOptions">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg" id="myModal">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title descriptionHeader">Booking Details</h4>
          <!--<button type="button" class="pop-up-btn-white" *ngIf="bookingStatus === 'Booked'" (click)="cancelBooking()">Cancel Booking</button>-->
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-9">
              <div>
                <h6 class="descriptionHeader" style="margin-top:20px">CUSTOMER</h6>
                <table class="table table-borderless bookedSlotsFieldName">
                  <tr>
                    <td>NAME</td>
                    <td class="bookedSlotsFieldValue">{{userName}} </td>
                  </tr>
                  <tr>
                    <td>EMAIL ID</td>
                    <td class="bookedSlotsFieldValue">{{userEmail}}</td>
                  </tr>
                  <tr>
                    <td>PHONE NO</td>
                    <td class="bookedSlotsFieldValue">{{userPhone}}</td>
                  </tr>
                </table>
                <h6 class="descriptionHeader" style="margin-top:20px">DETAILS</h6>
                <table class="table table-borderless">
                  <tr>
                    <td class="bookedSlotsFieldName">BOOKING ID</td>
                    <td class="bookedSlotsFieldValue">{{bookingId}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">BOOKING DATE</td>
                    <td class="bookedSlotsFieldValue">{{bookingDateTime}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">STATUS</td>
                    <td class="bookedSlotsFieldValue">{{bookingStatus}}</td>
                  </tr>

                  <tr>
                    <td class="bookedSlotsFieldName">TOTAL AMOUNT </td>
                    <td class="bookedSlotsFieldValue">{{totalAmount}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">PAYMENT STATUS </td>
                    <td class="bookedSlotsFieldValue">{{paymentStatus}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">PAYMENT TYPE</td>
                    <td class="bookedSlotsFieldValue">{{paymentType}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">PAYMENT TRANSACTION ID</td>
                    <td class="bookedSlotsFieldValue">{{transactionId}}</td>
                  </tr>
                  <!--<tr>
                    <td class ="bookedSlotsFieldName">TRANSACTION TIME </td>
                    <td class ="bookedSlotsFieldValue">Need to store</td>
                  </tr>-->
                  <tr>
                    <td class="bookedSlotsFieldName">NOTES </td>
                    <td class="bookedSlotsFieldValue">{{notes}}</td>
                  </tr>
                  <tr>
                    <td class="bookedSlotsFieldName">BOOKED BY </td>
                    <td class="bookedSlotsFieldValue">{{bookedBy}}</td>
                  </tr>
                </table>
                <h6 class="descriptionHeader">SLOTS BOOKED</h6>
                <div *ngFor="let slot of slotsBooked | keyvalue; let i = index" style="margin-top: 20px;">
                  <div class="row bookedSlotsVenueProduct" style="font: bold;">
                    <div>
                      <p>{{slot.value[0].productName}} : </p>
                    </div>
                    <div style="margin-left:30px">
                      <p>{{slot.value[0].venueName}}</p>
                    </div>
                    <div style="margin-left: 30px" *ngIf="slot.value[0].status === 'Booked'">
                      <p style="color: green;">{{slot.value[0].status}}</p>
                    </div>
                    <div style="margin-left: 30px" *ngIf="slot.value[0].status === 'Cancelled'">
                      <p style="color: red;">{{slot.value[0].status}}</p>
                    </div>
                    <div class="button-container" *ngIf="roleId < 3">
                      <mat-icon class="delete-slot" *ngIf="slot.value[0].status!=='Cancelled'"
                        (click)="cancelSlot(i)">delete</mat-icon>
                      <!--<div style="margin-left:30px" *ngIf="slot.value[0].status==='Cancelled'" >Slot Cancelled</div>-->
                      <div *ngIf="showConfirmationIndex === i" class="confirmation-popup">
                        <p>Are you sure you want to cancel this slot?</p>
                        <button class="pop-up-btn-white" (click)="confirmAction(slot.value[0])">Yes</button>
                        <button class="pop-up-btn-blue" style="margin-left: 20px;" (click)="cancelAction()">No</button>
                      </div>
                    </div>

                  </div>
                  <div class="row bookedSlotsFieldName">
                    <div class="col-12">
                      <span>{{ getFormattedDate(slot.value[0].date) }}</span>
                      <span style="margin-left: 10px;">{{ convertTo12HourFormat(slot.value[0].startTime) }} : {{
                        convertTo12HourFormat(slot.value[0].endTime) }}</span>
                      <span style="margin-left: 10px;">Rs {{slot.value[0].amount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">

          <!--<button type="button" class="pop-up-btn-white" *ngIf="bookingStatus === 'Booked'" (click)="cancelBooking()">Cancel Booking</button>-->
          <button type="button" class="pop-up-btn-white" *ngIf="bookingStatus === 'Booked' && roleId < 3"
            (click)="cancelBooking()">Cancel Booking</button>
          <button type="button" class="pop-up-btn-blue" style="margin-left: 30px;" (click)="hideModal()">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

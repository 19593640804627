<div class="main-container">
  <div class="content-wrapper">
    <div class="container">
<!--      <div class="row">
        <ol class="breadcrumb backgroundclr">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          &lt;!&ndash; <li class="breadcrumb-item"><a href="#">Sports</a></li> &ndash;&gt;
          <li class="breadcrumb-item"><a href="#">{{tenant?.tenantName}}</a></li>
          <li class="breadcrumb-item active" aria-current="page">Book Now</li>
        </ol>
      </div>-->
      <div class="row" style="padding-top: 30px;">
        <!-- TODO height 287px when new content is added bofere footer and after this division -->
        <div class="col-md-8 col-lg-8 product-panel">
          <div class="row">
            <div class="col-md-12 marg-right-mobile">
              <h5 class="header-title">
                {{tenant?.tenantName}} <br>
                <!-- <span class="bottom-text">{{categoryArr[0]}}</span> -->
              </h5>
            </div>
            <div class="col-md-12 marg-right-mobile">
              <form>
                <div class="form-row product-form">
                  <div class="row">
                     <div class="form-group col-md-6">
                      <label for="inputState">SERVICE</label>
                      <!-- <select #selectedService id="inputState" class="form-control" (change)="onChangeService($event.target.value)" *ngIf="isCatProd">
                        <option *ngFor="let service of categoryAndProduct.Badminton" value="{{service.id}}">{{service.name}}</option>
                      </select> -->
                      <select #selectedService id="inputState" class="form-control" (change)="onChangeService($event.target.value)">
                        <!-- <option *ngFor="let service of products" value="{{service.venueType}}">{{service.productName}}</option> -->
                        <!-- <option value="" disabled>Select Service</option> -->
                        <!-- For NEW API <option *ngFor="let service of productByCat" value="{{service.id}}">{{service.name}}</option> -->
                        <ng-container *ngFor="let service of products" >
                          <option  *ngIf="service.status === 'Active'" value="{{service.id}}">{{service.productName}}</option>
                        </ng-container>
                      </select>
                    </div>
                     <!--This Code allows bookings upto a month from the current date-->
                    <div *ngIf= "selectedServiceType === 'Single Session'" class="col-md-6 form-group">
                      <label for="toDate">DATE</label>
                      <div class="input-group">
                        <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                               class="form-control"
                               placeholder="yyyy-mm-dd"
                               name="toDate"
                               [min]="minDate"
                               [max]="maxDate"
                               (change)="onDateChange($event.target.value)"
                               (focus)="clearDate()" >
                      </div>
                    </div>
                    <div *ngIf="selectedServiceType === 'Multi Session'" class="col-md-6 form-group">
                      <div *ngIf="sessionType === 'Monthly'">

                        <label for="startDate">SELECT THE START DATE</label>
                        <!--<div class="input-group">
                          <input
                            type="date"
                            [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            name="toDate"
                            [min]="minDate"
                            [max]="maxDate"
                            [attr.disabledDates]="monthlyMultiBookingSelectedDates"
                            (change)="onDateChange1($event.target.value)"
                          >
                        </div>-->

                        <div *ngIf="monthlyMultiBookingOption === 'Selected Dates'">
                          <div *ngIf="monthlyMultiBookingSelectedDates.length > 0;">
                            <form>
                              <div *ngFor="let date of futureDates; let i = index">
                                <label>
                                  <input
                                    type="radio"
                                    name="startDate"
                                    [value]="date"
                                    [(ngModel)]="selectedStartDate"
                                    (change)="onStartDateChange(selectedStartDate)"
                                    [checked]="i === 0"
                                  />
                                  {{ date }}
                                </label>
                              </div>
                            </form>
                            <p>Selected Date: {{ selectedStartDate }}</p>
                          </div>
                        </div>

                        <!-- Date Picker for "Select Any Day" Option -->
                        <div *ngIf="monthlyMultiBookingOption === 'Start Any Day'">
                          <div class="input-group">
                            <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                                   class="form-control"
                                   placeholder="yyyy-mm-dd"
                                   name="toDate"
                                   [min]="minDate"
                                   [max]="maxDate"
                                   (change)="onDateChange($event.target.value)"
                                   (focus)="clearDate()" >
                          </div>
                        </div>
                      </div>
                      <div *ngIf="sessionType === 'Sessions'">
                        <label for="toDate">SELECT THE START DATE</label>
                        <div class="input-group">
                          <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                                 class="form-control"
                                 placeholder="yyyy-mm-dd"
                                 name="toDate"
                                 [min]="minDate"
                                 [max]="maxDate"
                                 (change)="onDateChange($event.target.value)"
                                 (focus)="clearDate()" >
                        </div>
                      </div>
                    </div>


                    <!-- This code does not have control over the booking date. It can go to any date in the future.
                    <div class="col-md-6 form-group" >
                      <label for="">DATE</label>
                      <div class="input-group">
                        <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'" class="form-control" placeholder="yyyy-mm-dd" value="{{latest_date1}}" name="toDate" min="{{latest_date1}}" [(ngModel)]="toDate" (change)="onDateChange($event.target.value)">
                      </div>
                    </div>-->
                  </div>
                </div>
                <div class="form-row product-form">
                  <div class="row">
                    <ul>
                      <li *ngFor="let day of days">
                        {{ day }}
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="allSlots && allSlots.length > 0">
                    <div class="row">
                      <div class="col-md-12 padding-20">
                        <h6 class="session-title">SELECT SLOT FOR BOOKING</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="row court-box" *ngFor="let slotItem of allSlots; let alindx = index">
                          <div class="col-2">
                              <div class="blueText" style="text-transform: uppercase; text-align: center; padding-top:30px;">
                                 {{slotItem?.type}}
                              </div>
                          </div>
                          <div class="col-10">
                            <div class="row" style="max-height: 200px; overflow-y: auto;overflow-x: hidden;">
                              <div class="col-md-4 col-sm-12 slots-session" *ngIf="slotItem?.before12">
                                <div class= "orangeText" style="text-align: center;">Morning</div>
                                <div class="row justify-content-center">
                                  <div *ngFor="let slot of slotItem?.before12; let slotId = index" class="box-div {{bntStyle}} {{slotItem?.mainService}}" [ngStyle]="{'background-color': getSlotBGColor(slot.uniqueId)}" (click)="getSlotsDetails_TD($event, slot.uniqueId, slot.venueId, slot.startTime, slot.endTime, slot.price, slotId, slotItem?.type, slotItem?.mainService)" id="slot_{{slot.id}}">
                                    <!--<div *ngFor="let slot of slotItem?.before12; let slotId = index" class="box-div {{bntStyle}} {{slotItem?.mainService}}" (click)="getSlotsDetails($event, slot.startTime, slot.endTime, slot.price, slotId, slotItem?.type, slotItem?.mainService)" id="slot_{{slot.id}}">-->
                                    <h6 class="time-h6">{{ slot.startTime }}</h6>
                                    <!-- <h6 class="time-h6">{{ slot.endTime }}</h6> -->
                                    <span class="price-sp">{{ slot.price | number:'1.2-2'}}/-</span>
                                    <div class="priceSp1" *ngIf="onSinglehide">67%</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 slots-session" *ngIf="slotItem?.bwtn12To6">
                                <div class= "orangeText" style="text-align: center;">Afternoon</div>
                                <div class="row justify-content-center">
                                  <div *ngFor="let slot of slotItem?.bwtn12To6; let slotId = index" class="box-div {{bntStyle}} {{slotItem?.mainService}}" [ngStyle]="{'background-color': getSlotBGColor(slot.uniqueId)}" (click)="getSlotsDetails_TD($event, slot.uniqueId, slot.venueId, slot.startTime, slot.endTime, slot.price, slotId, slotItem?.type, slotItem?.mainService)" id="slot_{{slot.id}}">
                                    <h6 class="time-h6">{{ slot.startTime }}</h6>
                                    <span class="price-sp">{{ slot.price | number:'1.2-2'}}/-</span>
                                    <div class="priceSp1" *ngIf="onSinglehide">67%</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 slots-session" *ngIf="slotItem?.after6">
                                <div class= "orangeText" style="text-align: center;">Evening</div>
                                <div class="row justify-content-center">
                                  <div *ngFor="let slot of slotItem?.after6; let slotId = index" class="box-div {{bntStyle}} {{slotItem?.mainService}}" [ngStyle]="{'background-color': getSlotBGColor(slot.uniqueId)}" (click)="getSlotsDetails_TD($event, slot.uniqueId, slot.venueId, slot.startTime, slot.endTime, slot.price, slotId, slotItem?.type, slotItem?.mainService)" id="slot_{{slot.id}}">
                                    <h6 class="time-h6">{{ slot.startTime }}</h6>
                                    <span class="price-sp">{{ slot.price | number:'1.2-2'}}/-</span>
                                    <div class="priceSp1" *ngIf="onSinglehide">67%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--- Only Mobile Buttons-->
                    <div class="row d-block d-sm-none fixed-bottom mobile-bottom-btns">
                      <div class="mobile-btn cancel">
                        <button type="button" class="btn" (click)="cancelSlots()">Cancel</button>
                      </div>
                      <div class="mobile-btn book-now">
                        <button type="button" data-toggle="modal" data-target="#myModal" (click)="showModal()" class="btn">Book Now</button>
                      </div>
                    </div>
                    <!--- Only Mobile Buttons-->

                    <div class="row mainSlots">
                      <div class="col-md-4 col-6  avl-slots">
                        <div id="avail_slots"></div> Available Slots
                      </div>
                      <div class="col-md-4 col-6 your-booking">
                        <div class="yourBk slot_selected" id="your_booking"></div> Your Booking
                      </div>
                      <div class="col-md-4 slot-booked">
                        Slots Booked : {{numberOfSlotsSelected}}
                        <!--Slots Booked : {{bookedslotsLength}}/{{slotsAllLength}}-->
                        <!-- Slots Booked : {{timeSlotsArr?.length}}/{{allSlots?.length}} -->
                      </div>
                    </div>


                  </div>
                  <div *ngIf="allSlots && allSlots.length === 0"style="text-align:center;">

                    <h6>No slots are available</h6>

                  </div>
                  <div class="row mbl-btn d-none d-sm-block">
                    <button type="submit" class="btn btn-outline-dark cancel_btn" (click)="cancelSlots()">Cancel</button>
                  </div>

                </div>
                <!-- <div class="row mbl-btn d-none d-sm-block">
                  <button type="submit" class="btn btn-outline-primary mr-2 float-right">Cancel</button>
                  <button type="submit" (click)="addSlots(toDate, slotId)" class="btn btn-primary mr-2 book-now-btn float-right">Add Slots</button>
                  <div [innerHTML]="alertMessages"></div>
                </div>
                <div class="d-block d-sm-none add-slot-mobile">
                  <a (click)="addSlots(toDate, slotId)">Add Slot +</a>
                </div> -->

                  <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal" (click)="showModal()">
                    Open modal
                  </button> -->


                  <!-- The Modal -->
                  <div class="modal fade bd-example-modal-lg" id="myModal">
                    <div class="modal-dialog modal-lg modal-dialog-scrollable">
                      <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                          <h4 class="modal-title">Confirm Details</h4>
                          <!-- <button type="button" class="close" (click)="hideModal()">&times;</button> -->
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-md-7 col-lg">
                              <div>
                                <label class="bold">Facility Details</label>
                                <table class="table table-borderless">
                                  <tr>
                                    <td>NAME</td>
                                    <td>{{tenant?.tenantName}}</td>
                                  </tr>
                                  <tr>
                                    <td>ADDRESS</td>
                                    <td>{{tenant?.address?.addressLine1}}, {{tenant?.address?.addressLine2}}, {{tenant?.address?.city}}, {{tenant?.address?.pinCode}}, </td>
                                  </tr>
                                </table>
                              </div>
                              <div>
                                <label class="bold">User Details</label>
                                <table class="table table-borderless">
                                  <tr>
                                    <td>NAME</td>
                                    <td>{{userb?.firstName}} {{userb?.lastName}}</td>
                                  </tr>
                                  <tr>
                                    <td>EMAIL ID</td>
                                    <td>{{userb?.username}}</td>
                                  </tr>
                                  <!--<tr>
                                    <td>PHONE NO</td>
                                    <td>{{userb?.phone}}</td>
                                  </tr>-->
                                </table>
                              </div>
                              <div>
                                <label class="bold">Booking Details</label>
                                  <table class="slot-data" cellpadding="0" cellspacing="0" width="100%" *ngFor="let slotObj of selectedSlotsMap | keyvalue;">
                                    <tr>
                                      <td colspan="8" class="bold">{{slotObj.value.ss_service}} : {{slotObj.value.ss_slotType}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 2px; text-align: left;  font-size: 0.8rem;">{{slotObj.value.ss_slotDate}}</td>
                                      <td style="padding-right: 2px; text-align: left;  font-size: 0.8rem;">{{slotObj.value.ss_startTime}}-{{slotObj.value.ss_endTime}}</td>
                                      <td style="padding-right: 2px; text-align: left;  font-size: 0.8rem;">{{slotObj.value.amount | number:'1.2-2'}}</td>
                                      <td align="center" valign="" style="width:10%">
                                        <i class="bi bi-trash" (click)="deleteSelectedSlot($event, slotObj)"></i>
                                      </td>
                                    </tr>
                                  </table>
                              </div>
                              <div>
                                <label class="bold">Payment</label>
                                <table class="table table-borderless noboderlast">
                                  <tr>
                                    <td colspan="2">TOTAL</td>
                                    <td colspan="2"><b>Rs. {{totalSum}}/-</b></td>
                                  </tr>
                                </table>
                              </div>
                              <div class="justify-content-center">
                                <button type="button" class="btn btn-outline-dark cancel_btn" id="close-modal" (click)="hideModal()">Cancel</button>
                                <button type="button" class="btn btn-primary book-now-btn" style="margin-left:20px;" (click) = "confirmBooking()">Pay Now</button>
                              </div>
                            </div>
                            <!--<div class="justify-content-center">
                                <button type="button" class="btn btn-outline-dark cancel_btn" id="close-modal" (click)="hideModal()">Cancel</button>
                                <button type="button" class="btn btn-primary book-now-btn">Pay Now</button>
                            </div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
        <!--<div class="col-md-4 col-lg-4 summary-panel"*ngIf="isTotalSumZero">-->
        <div class="col-md-4 col-lg-4 summary-panel">
          <div class="card border-light mb-4">
            <h5 class="card-header bg-transparent border-light">Summary Panel
              <!-- <span class="slot-text">1 Slot</span> -->
            </h5>
            <div class="card-body"style="height: 450px;overflow-y: auto">
              <div class="row">
                <div class="col-md-12 booking-details">
                  <div class="summary-slots-details">
                    <div class="row margin-0">
                      <div class="col-md-6" *ngIf="selectedSlotsMap.size">
                        <h6 style="padding-top:7px;font-size:1.0rem bold;font-family: 'OpenSans-B';line-height: 25px;">Total: Rs {{totalSum}}/-</h6>
                      </div>
                      <div class="col-md-6" *ngIf="!selectedSlotsMap.size">
                        <h6 style="padding-top:7px;font-size:1.0rem;font-family: 'Nunito-R';line-height: 25px;">Add Slots for Booking</h6>
                      </div>
                      <div class="col-md-6 bg-transparent border-light" *ngIf="!selectedSlotsMap.size">
                        <button type="button" disabled data-toggle="modal" data-target="#myModal" (click)="showModal()" class="btn btn-secondary book-now-btn-disabled float-right d-none d-sm-block" >Book Now</button>
                      </div>
                      <div class="col-md-6 bg-transparent border-light" *ngIf="selectedSlotsMap.size">
                        <button type="button" enabled data-toggle="modal" data-target="#myModal" (click)="showModal()" class="btn btn-secondary book-now-btn float-right d-none d-sm-block" >Book Now</button>
                      </div>
                    </div>
                    <div class="row summary-slots-row" >

                      <table width="100%" *ngFor="let slotObj of selectedSlotsMap | keyvalue;" class="trash_{{slotObj.trash}}">
                        <tr>
                          <td colspan="10" class="bold" style="font size; font-family: 'Nunito-R' !important;">{{slotObj.value.ss_service}} : {{slotObj.value.ss_slotType}}</td>
                          <!--<td colspan="8" class="bold" style="font-size: bold; font-family: 'OpenSans-B'">{{key.value.ss_service}} : {{key.value.ss_slotType}}</td>-->
                        </tr>
                        <tr>
                          <td  style=" padding-right: 2px; text-align: left;  font-size: 0.8rem; !important;" >{{slotObj.value.ss_slotDate}}</td>
                          <td style=" padding-right: 2px; text-align: left;  font-size: 0.8rem; !important;">{{slotObj.value.ss_startTime}}-{{slotObj.value.ss_endTime}}</td>
                          <td style=" padding-right: 2px; text-align: left;  font-size: 0.8rem; !important;">{{ slotObj.value.amount | number:'1.2-2'}}</td>
                          <td align="left" valign="middle" style=" padding-right: 2px; !important;" >
                            <i class="bi bi-trash" (click)="deleteSelectedSlot($event, slotObj)"></i>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <!--<div class="row summary-slots-row" *ngFor="let mainArr of mainserviceArrFinal">
                      <div class="col-md-12">
                        <h6 class="summary-title">{{mainArr?.name}}</h6>
                      </div>
                      <div class="col-md-12">
                        <table class="slot-data" cellpadding="0" cellspacing="0" width="100%" *ngFor="let subArr of mainArr?.sub_arr">
                          <tr>
                            <label class="bold padding 10">Venue</label>
                            <td colspan="8">{{subArr?.name}}</td>
                          </tr>
                          <tr *ngFor="let child of subArr?.child_arr">
                            <td style="width:36%; text-align: left;">{{child.slotDayNew}}</td>
                            <td style="width:33%; text-align: center;">{{child.time}}-{{child.endTime}}</td>
                            <td style="width:31%; text-align: center;">{{ child.price | number:'1.2-2'}}</td>
                            <td align="center" valign="" style="width:10%">
                              <i class="bi bi-trash"></i>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>-->

                  </div>
                  <!--<div class="summary-slots" *ngIf="isTotalSumZero">
                    <div *ngIf="!isTotalSumZero" class="no-data-slots">
                        No Data Available
                    </div>
                    <div *ngIf="isTotalSumZero">
                      <div *ngFor="let itemSlot of timeSlotsArr; let slotIndex=index;" id="slotRow_{{slotIndex}}" class="trash_{{itemSlot.trash}}">
                        <span class="sum-data">{{itemSlot.slotDayNew}}</span>
                        <span class="sum-data">{{itemSlot.time}}</span>
                        <span class="sum-data">{{itemSlot.price}}</span>
                        <span class="sum-data"><i id="trash_{{itemSlot.trash}}" class="bi bi-trash" (click)="removeSlotItem($event, itemSlot.price, slotIndex)"></i></span>
                      </div>
                    </div>
                  </div> &ndash;&gt;-->
                  <!-- <div *ngIf="isTotalSumZero" class="sumarry-total-sum">
                    <h6>Payment</h6>
                    <div>
                      <span class="sum-data">TOTAL</span>
                      <span class="sum-data">{{totalSum}}/-</span>
                      <span class="sum-data"></span>
                    </div>
                  </div>

                  <div class="summary-slots" *ngIf="isTotalSumZero">
                    <div *ngIf="!isTotalSumZero" class="no-data-slots">
                        No Data Available
                    </div>
                    <div *ngIf="isTotalSumZero">
                      <div *ngFor="let itemSlot of timeSlotsArr; let slotIndex=index;" id="slotRow_{{slotIndex}}" class="trash_{{itemSlot.trash}}">
                        <span class="sum-data">{{itemSlot.slotDayNew}}</span>
                        <span class="sum-data">{{itemSlot.time}}</span>
                        <span class="sum-data">{{itemSlot.price}}</span>
                        <span class="sum-data"><i id="trash_{{itemSlot.trash}}" class="bi bi-trash" (click)="removeSlotItem($event, itemSlot.price, slotIndex)"></i></span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isTotalSumZero" class="sumarry-total-sum">
                    <h6>Payment</h6>
                    <div>
                      <span class="sum-data">TOTAL</span>
                      <span class="sum-data">{{totalSum}}/-</span>
                      <span class="sum-data"></span>
                    </div>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<paytm-checkout *ngIf="isCheckoutVisible"></paytm-checkout>

<!-- <script type="application/javascript" src="https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/NgXarD42151468596686.js" onload="onScriptLoad();" crossorigin="anonymous"></script>
<script>
function onScriptLoad(){
var config = {
"root": "",
"flow": "DEFAULT",
"data": {
"confirmationId": "test1056", /* update order id */
"token": "89c2446b174e4b4096ad10df8d3209dc1676880761206", /* update token value */
"tokenType": "TXN_TOKEN",
"amount": "1.00" /* update amount */
},
"handler": {
"notifyMerchant": function(eventName,data){
console.log("notifyMerchant handler function called");
console.log("eventName => ",eventName);
console.log("data => ",data);
}
}
};
if(window.Paytm && window.Paytm.CheckoutJS){
window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
// initialze configuration using init method
window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
// after successfully updating configuration, invoke JS Checkout
window.Paytm.CheckoutJS.invoke();
}).catch(function onError(error){
console.log("error => ",error);
});
});
}
}
</script> -->
